import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { Button } from 'components';
import apiReq from 'store/api';
import SocialMediaSignIn from 'components/SocialMediaSignIn';
import { ReactComponent as Info } from 'assets/images/icons/info.svg';

type FormData = {
    name: string;
    email: string;
    password: string;
    confirm_password: string;
};

export const Register = () => {
    const [error, setError] = useState("");

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        watch,
        getValues,
        formState: { errors, isSubmitting }
    } = useForm<FormData>();

    const handleSuccess = (res: AxiosResponse) => {
			localStorage.setItem('email', getValues().email);
			navigate(`/email-verification?c=${res.data['token']}`);
    };

    const onSubmit: SubmitHandler<FormData> = async(data) => {
			try {
				const res = await apiReq.post('/api/registration', data);
				handleSuccess(res);
			} catch (err: any) {
				setError(err.response.data?.error);
			}
		};

    return (
      <form className="signup-container-form" onSubmit={handleSubmit(onSubmit)}>
          <h1 className="signup-container-form-title">
            Welcome to CoinStorm!
          </h1>
					<SocialMediaSignIn onError={setError} />
          <span className="signup-container-form-terms mt-2">
              By clicking sign up you confirm our{' '}
              <a href="/#">Terms of Service</a>{' '}
              and{' '}
              <a href="/#">Privacy Policy</a>.
          </span>
          <div className="input-wrapper">
            <label className="signup-container-form-subtitle">
                Full name
            </label>
            <input
                {...register('name', {
                    required: 'Required',
                    pattern: {
                        value: /^[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/i,
                        message: "invalid symbols"
                    }
                })}
                type="text"
                placeholder="Satoshi Nakamoto"
                className={`signup-container-form-input${errors.name ? ' border-1-orange' : ''}`}
            />
            <span className={`color-orange font-sm ${errors.name ? 'block' : 'none'}`}>
                <span className="mr-1">
                    <Info />
                </span>
                {errors.name?.message}
            </span>
        </div>
        <div className="input-wrapper">
            <label className="signup-container-form-subtitle">
                E-mail
            </label>
            <input
                {...register('email', {
                    required: 'Required',
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i,
                        message: "invalid email address"
                    }
                })}
                type="text"
                placeholder="email@coinbooster.com"
                className={`signup-container-form-input${errors.email ? ' border-1-orange' : ''}`}
            />
            <span className={`color-orange font-sm ${errors.email ? 'block' : 'none'}`}>
                <span className="mr-1">
                    <Info />
                </span>
                {errors.email?.message}
            </span>
        </div>
        <div className="input-wrapper">
            <label className="signup-container-form-subtitle">
                Password
            </label>
            <input
                {...register('password', {
                    required: 'Required',
                    min: {
                        value: 5,
                        message: 'The password must be at least 5 characters long.'
                    }
                })}
                type="password"
                placeholder="Password"
                className={`signup-container-form-input${errors.password ? ' border-1-orange' : ''}`}
            />
            <span className={`color-orange font-sm ${errors.password ? 'block' : 'none'}`}>
                <span className="mr-1">
                    <Info />
                </span>
                {errors.password?.message}
            </span>
        </div>
        <div className="input-wrapper">
            <label className="signup-container-form-subtitle">
                Confirm Password
            </label>
            <input
                {...register('confirm_password', {
                    required: 'Required',
                    validate: (val: string) => {
                        if (watch('password') !== val) return "Your passwords do not match";
                    }
                })}
                type="password"
                placeholder="Confirm Password"
                className={`signup-container-form-input${errors.confirm_password ? ' border-1-orange' : ''}`}
            />
            <span className={`color-orange font-sm ${errors.confirm_password ? 'block' : 'none'}`}>
                <span className="mr-1">
                    <Info />
                </span>
                {errors.confirm_password?.message}
            </span>
        </div>
          {error ? <span className="form-error">{error}</span> : null}

          <Button
              title="Sign up"
              color="orange"
              className="signup-container-form-submit"
              type="submit"
              disabled={isSubmitting}
          />
          <span className="signup-container-form-terms">
              If you already registred - {' '}
              <Link to="/login">
                Log in
              </Link>
          </span>
      </form>
    );
};
