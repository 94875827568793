import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import ProtectedLayout from './ProtectedLayout';
import AuthLayout from 'AuthLayout';
import {
  Buy,
  Send,
  Login,
  Register,
  Dashboard,
  Transactions
} from 'pages';
import { MobileMenu } from './components';
import Notifications from 'components/Notifications';
import PaymentResult from 'pages/PaymentResult';
import WagmiProvider from './WagmiProvider';

const Profile = lazy(() => import('./pages/Profile'));
const EmailVerification = lazy(() => import('./pages/EmailVerification'));
const FAQ = lazy(() => import('./pages/FAQ'));
const ResetPasswordConfirm = lazy(() => import('./pages/ResetPasswordConfirm'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

function App() {
  return (
    <div className="App">
      <MobileMenu />
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/reset-password"
            element={
              <Suspense fallback={<></>}>
                <ResetPassword />
              </Suspense>
            }
          />
          <Route
            path="/reset-password/confirm"
            element={
              <Suspense fallback={<></>}>
                <ResetPasswordConfirm />
              </Suspense>
            }
          />
          <Route
            path="/email-verification"
            element={
              <Suspense fallback={<></>}>
                <EmailVerification />
              </Suspense>
            }
          />
        </Route>
        <Route element={<WagmiProvider />}>
          <Route
            path="/dashboard"
            element={
              <ProtectedLayout title="Wallet">
                <Dashboard />
              </ProtectedLayout>
            }
          />
          <Route
            path="/transactions"
            element={
              <ProtectedLayout title="Transaction">
                <Transactions />
              </ProtectedLayout>
            }
          />
          <Route
            path="/buy"
            element={
              <ProtectedLayout title="Buy">
                <Buy />
              </ProtectedLayout>
            }
          />
          <Route
            path="/payment-result"
            element={
              <ProtectedLayout title="Payment result">
                <PaymentResult isSuccessful />
              </ProtectedLayout>
            }
          />
          <Route
            path="/send"
            element={
              <ProtectedLayout title="Send">
                <Send />
              </ProtectedLayout>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedLayout title="Profile">
                <Suspense fallback={<></>}>
                  <Profile />
                </Suspense>
              </ProtectedLayout>
            }
          />
          <Route
            path="/help"
            element={
              <ProtectedLayout title="Help center">
                <Suspense fallback={<></>}>
                  <FAQ />
                </Suspense>
              </ProtectedLayout>
            }
          />
        </Route>
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
      <Notifications />
    </div>
  );
}

export default App;
