import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';

import type { RootState } from '..';
import type { UserState, IUser } from 'type';
import { AxiosInstance, AxiosError } from "axios";
import { addNotification } from './notificationsSlice';

export const getUser = createAsyncThunk<IUser, void, { extra: AxiosInstance }>('user/get', async (_, { extra, rejectWithValue }) => {
  try {
    const res = await extra.get('/api/profile');
    return res.data;
  } catch (err) {
    const error = err as AxiosError;
    return rejectWithValue(error?.response?.statusText);
  }
});

export const editUser = createAsyncThunk<IUser, any, { extra: AxiosInstance }>
  ('user/patch', async (body, { dispatch, extra, rejectWithValue }) => {
  try {
    const res = await extra.patch('/api/profile', body);
    dispatch(addNotification({ title: 'Success!', type: 'success', message: res.data?.message }));
    return res.data;
  } catch (err) {
    const error = err as AxiosError;
    return rejectWithValue(error?.response?.statusText);
  }
});

export const deleteAvatar = createAsyncThunk<IUser, void, { extra: AxiosInstance }>
  ('avatar/delete', async (_, { dispatch, extra, rejectWithValue }) => {
  try {
    const res = await extra.delete('/api/profile/avatar');
    dispatch(addNotification({ title: 'Success!', type: 'success', message: res.data?.message }));
    return res.data;
  } catch (err) {
    const error = err as AxiosError;
    return rejectWithValue(error?.response?.statusText);
  }
});

export const uploadAvatar = createAsyncThunk<string, FormData, { extra: AxiosInstance }>
  ('avaar/upload', async (body, { dispatch, extra, rejectWithValue }) => {
  try {
    const res = await extra.post('/api/profile/avatar', body);
    dispatch(addNotification({ title: 'Success!', type: 'success', message: res.data?.message }));
    return res.data.avatar;
  } catch (err: any) {
    dispatch(addNotification({ title: 'Error!', type: 'error', message: err?.response?.data?.error }));
    return rejectWithValue(err?.response?.data?.error);
  }
});

export const changePassword = createAsyncThunk<void, any, { extra: AxiosInstance }>
  ('user/password', async (body, { dispatch, extra, rejectWithValue }) => {
  try {
    const res = await extra.post('/api/profile/change-password', body);
    dispatch(addNotification({ title: 'Success!', type: 'success', message: res.data?.message }));
  } catch (err: any) {
    dispatch(addNotification({ title: 'Error!', type: 'error', message: err?.response?.data?.error }));
    return rejectWithValue(err?.response?.data?.error);
  }
});

const initialState: UserState = {
  loading: false,
  error: '',
  data: {
    name: '',
    email: '',
    nickname: '',
    avatar: '',
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(uploadAvatar.fulfilled, (state, action) => {
        if (state.data) state.data.avatar = action.payload;
      })
      .addCase(deleteAvatar.fulfilled, (state) => {
        if (state.data) state.data.avatar = '';
      })
  },
});

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
