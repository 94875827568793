import { useState, useEffect, useRef } from 'react';

type ResultType = {
  value: string | number;
};

const useAnimatedValue = (
  initialValue: number = 0,
  newValue: number | null = null,
  decimal: number = 2
): ResultType => {
  const [value, setValue] = useState<number>(initialValue);
  const animationFrameRef = useRef<number | null>(null);
  const lastAnimatedValue = useRef<number>(initialValue);

  useEffect(() => {
    if (newValue !== null && newValue !== lastAnimatedValue.current) {
      const maxFrames = 60;
      const maxDuration = 600;

      const difference = Math.abs(newValue - value);
      const desiredFPS = Math.min(maxFrames, difference * 10);
      const frameDuration = 1000 / desiredFPS;
      const frames = Math.min(Math.ceil(maxDuration / frameDuration), difference);

      const increment = frames ? difference / frames : 0;

      const animate = () => {
        if (Math.abs(difference) <= Math.abs(increment)) {
          setValue(newValue || 0);
        } else {
          setValue((prev) => +prev.toFixed(decimal) + increment);
        }
      };

      animationFrameRef.current = requestAnimationFrame(animate);

      return () => {
        if (animationFrameRef.current !== null) {
          cancelAnimationFrame(animationFrameRef.current);
        }
      };
    }
  }, [value, newValue, decimal]);

  return { value: value.toFixed(decimal) };
};

export default useAnimatedValue;
