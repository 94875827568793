import React from 'react';
import Card from '../Card';
import { Link } from 'react-router-dom';

import btc from 'assets/images/btc.png';
import eth from 'assets/images/eth.png';
import sol from 'assets/images/sol.png';
import doge from 'assets/images/doge.svg';
import { ReactComponent as ChartUp } from 'assets/images/chart-up.svg';
import { ReactComponent as ChartDown } from 'assets/images/chart-down.svg';
import { ICoin } from 'type';
import { useAppSelector } from 'hooks';
import './CardCurrency.scss';
import Button from 'components/Button';

const currencies: any = {
  btc: {
    icon: btc,
    name: 'Bitcoin',
    symbol: 'BTC',
  },
  eth: {
    icon: eth,
    name: 'Ethereum',
    symbol: 'ETH',
  },
  sol: {
    icon: sol,
    name: 'Solana',
    symbol: 'SOL',
  },
  doge: {
    icon: doge,
    name: 'Dogecoin',
    symbol: 'DOGE',
  },
};

export default function CardCurrency({ name }: any) {
  const coin: ICoin = useAppSelector((state: any) => {
    if (!state.coins.list.length) {
      return null;
    }
    return state.coins.list.filter((item: ICoin) => item.symbol === currencies[name].name.toLowerCase())[0];
  });

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);
  };

  return (
    <Card>
      <div className="card-currency">
        <div className="flex align-start">
          <div className="currency-icon mr-2">
            <img src={currencies[name].icon} alt={name} />
          </div>
          <div className="currency-info">
            <div className="currency-info-name fw-600">{currencies[name].name}</div>
            <div className="currency-info-symbol fw-500">{currencies[name].symbol}</div>
          </div>
        </div>
        <div className="mt-2 mb-2">
          <div className={`font-xl ${coin?.usd_24h_change! >= 0 ? 'color-green-100' : 'color-orange'} fw-600 price-container text-nowrap`}>
            {coin ? formatPrice(coin.price as number).replace('$', '$ ') : null}
            {coin?.usd_24h_change! >= 0 ? <ChartUp className="ml-1" /> : <ChartDown className="ml-1" />}
          </div>
        </div>
        <div className="flex card-currency-footer">
          <Button to={`/buy?currency=${name}`} as={Link} title="Buy currency" color="white" />
        </div>
      </div>
    </Card>
  );
}
