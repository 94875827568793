import React, { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts';

import { selectBalanceHistory, selectIsChartAvailable } from 'store/features/balanceHistorySlice';
import { Button } from '..';
import './Balance.scss';
import { useAppSelector } from 'hooks';
import { IBalanceHistory } from 'type';
import useAnimatedValue from 'hooks/useAnimatedValue';
import { CardCurrency, CardEmptyBalance } from 'components';

type BalanceProps = {
  balance: number;
};

export const Balance: React.FC<BalanceProps> = ({ balance }) => {
  const { data, loading, error } = useAppSelector(selectBalanceHistory);
  const [chartData, setChartData] = useState<any>(data);
  const isChartAvailable = useAppSelector(selectIsChartAvailable);
  const navigate = useNavigate();
  const { value } = useAnimatedValue(0, balance, 2);

  const onHistorySuccess = (data: IBalanceHistory) => {
    if (!isChartAvailable) return;
    setTimeout(() => {
      setChartData(data);
    }, 1000);
  };

  const setMaxYaxisVal = (dataMax: number) => Math.ceil(dataMax / 10) * 10;

  useLayoutEffect(() => {
    if (!loading && !error) onHistorySuccess(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error]);

  if (!balance) return (
    <div className="balance-empty-bar mb-2">
      <CardEmptyBalance />
      <div className="balance-empty-bar-currencies">
        <CardCurrency name="btc" />
        <CardCurrency name="eth" />
      </div>
    </div>
  );

  return (
    <div className="balance-container mb-2">
      <div className="balance">
        <div className="balance-info">
          <div className="balance-info-block">
            <div className="balance-info-block-val">
              <span className="balance-info-title">Balance</span>
              <span className="balance-info-value">
                {value}
                <span className="dollar-sign">$</span>
              </span>
              <span className="balance-info-helper">Your total balance</span>
            </div>
            <div className="balance-info-buttons">
              <Button title="Deposit" color="orange" clickHandler={() => navigate('/buy')} />
              <Button title="Send" color="white" clickHandler={() => navigate('/send')} />
            </div>
          </div>
        </div>
        <div className="balance-chart relative">
          <ResponsiveContainer className={!isChartAvailable ? 'blur-disabled' : ''}>
            <AreaChart
              data={loading && data.length ? data : chartData}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#F67554" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#F67554" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="name" />
              <YAxis
                type="number"
                domain={[0, setMaxYaxisVal]}
                orientation="right"
              />
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              {isChartAvailable && <Tooltip />}
              <Area
                type="monotone"
                dataKey="uv"
                stroke="#F67554"
                fillOpacity={0.4}
                fill="url(#colorUv)"
                isAnimationActive
                animationDuration={1000}
              />
            </AreaChart>
          </ResponsiveContainer>
          {
            !isChartAvailable && !loading ?
            <div className="chart-empty-state">
              <p>Sorry, but there are not enough data to display the chart at this time</p>
            </div>: null
          }
        </div>
      </div>
    </div>
  );
};
