import React, { FC } from "react";

import ramp from 'assets/images/payment-systems/ramp.svg';
import itez from 'assets/images/payment-systems/itez.svg';
import transak from 'assets/images/payment-systems/transak.svg';
import moonPay from 'assets/images/payment-systems/moon-pay.svg';
import './PaymentMethods.scss'
import { Button } from "components";
import { PAYMENT_METHODS } from '../utils';
import { Currency } from "type";

type Logos = {
  [name: string]: string 
};

type Props = {
  token: Currency
};

export const PaymentMethods: FC<Props> = ({ token }) => {
  const logos: Logos = { itez, transak, moonPay, ramp };

  return (
    <div className="flex flex-column mb-4">
      {
        PAYMENT_METHODS.map((service) => 
          <div className="PaymentMethod flex bg-gray-100 align-center mb-1">
            <img src={logos[service.name]} alt="RAMP" />
            <p className="color-gray font-xs ml-2 mr-2">
              {service.desc}
            </p>
            <Button 
              title="Buy" 
              color="orange"
              clickHandler={() => window.open(service.link, '_blank')}
            />
          </div>
        )
      }
    </div>
  );
};

export default PaymentMethods;
