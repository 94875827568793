import React, { ReactNode } from 'react';
import "./Card.scss";

type LayoutProps = {
  children?: ReactNode;
  className?: string
}

const Card: React.FC<LayoutProps> = ({ children, className='' }) => {
    return (
        <div className={`card p-3 ${className}`}>
            {children}
        </div>
    );
};

export default Card
