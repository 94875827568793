import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosInstance } from 'axios';

import { RootState } from '..';
import type { IExternalWallet, IExternalWalletState } from 'type';

// send external wallet data to backend to save in database and return wallet id to frontend to save in redux store and use for transactions and balances
export const connectExternalWallet = createAsyncThunk<
  IExternalWallet,
  { address: string; network: string },
  { extra: AxiosInstance }
>('wallet/connect-external', async ({ address, network }, { getState, extra, rejectWithValue }) => {
  try {
    const res = await extra.post(`/api/connect-external-wallet`, { address, network });
    return res.data;
  } catch (err) {
    const error = err as AxiosError;
    return rejectWithValue(error?.response?.statusText);
  }
});

export const getExternalWallet = createAsyncThunk<IExternalWallet, void, { extra: AxiosInstance }>(
  'wallet/get-external',
  async (_, { getState, extra, rejectWithValue }) => {
    try {
      const res = await extra.get(`/api/connect-external-wallet`);
      return res.data;
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.response?.statusText);
    }
  }
);

export const deleteExternalWallet = createAsyncThunk<void, void, { extra: AxiosInstance }>(
  'wallet/delete-external',
  async (_, { getState, extra, rejectWithValue }) => {
    try {
      const res = await extra.delete(`/api/connect-external-wallet`);
      return res.data;
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.response?.statusText);
    }
  }
);

const initialState: IExternalWalletState = {
  loading: false,
  error: null,
  data: {
    address: '',
    balance: 0,
    name: 'External Wallet',
    network: '',
    ticker: '',
    usdBalance: 0,
  },
};

export const externalWalletsSlice = createSlice({
  name: 'externalWallets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getExternalWallet.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(connectExternalWallet.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(deleteExternalWallet.fulfilled, (state, action) => {
      state.data = initialState.data;
    });
  },
});

export const selectExternalWallets = (state: RootState) => state.externalWallets;

export default externalWalletsSlice.reducer;
