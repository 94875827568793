import { configureStore, ThunkDispatch, AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';

import assetReducer from './features/assetsSlice';
import coinReducer from './features/coinsSlice';
import userReducer from './features/userSlice';
import uiReducer from './features/uiSlice';
import balanceHistoryReducer from './features/balanceHistorySlice';
import walletsSlice from './features/walletsSlice';
import externalWalletsSlice from './features/walletConnectSlice';
import notificationsSlice from './features/notificationsSlice';
import transactionsSlice from './features/transactionsSlice';
import apiReq from './api';

const reducers = combineReducers({
  user: userReducer,
  ui: uiReducer,
  coins: coinReducer,
  balanceHistory: balanceHistoryReducer,
  wallets: walletsSlice,
  externalWallets: externalWalletsSlice,
  assets: assetReducer,
  notifications: notificationsSlice,
  transactions: transactionsSlice,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  const appState: RootState = action.type === 'auth/logout' ? {} : state;
  return reducers(appState, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: apiReq,
      },
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;

export default store;
