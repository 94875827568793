import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import type { RootState } from '..';
import type { INotification, NotificationType } from 'type';

const initialState: INotification[] = [];

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<{ title: string, message: string, type: NotificationType }>) => {
      const { payload } = action;
      const notification = {
        id: uuid(),
        title: payload.title,
        message: payload.message,
        type: payload.type
      };
      state.push(notification);
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      return state.filter((n) => n.id !== action.payload);
    }
  },
});

export const { addNotification, removeNotification } = notificationsSlice.actions;

export const selectNotifications = (state: RootState) => state.notifications;

export default notificationsSlice.reducer;
