import React from "react";

import { Button } from "components";
import Spinner from "components/Spinner";

const TransactionsEmpty: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  return (
    <div className="transactions-empty">
      <table className="history">
        <tr>
          <th>Time</th>
          <th className="narrow-cell">Type</th>
          <th className="narrow-cell">Coin</th>
          <th>Amount</th>
          <th className="hide">Destination</th>
          <th className="hide">TxID</th>
          <th>Status</th>
          <th></th>
        </tr>
      </table>
      <div className="transactions-empty-content relative">
        {
          isLoading ?
          <Spinner
            size="sm"
            isCentered
          />
          :
          <>
            <div className="transactions-empty-icon">
              <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" width="56" height="56" rx="28" fill="#F67554" fill-opacity="0.1" />
                <g clip-path="url(#clip0_2567_14789)">
                  <path
                    d="M28.5 42C24.787 42 21.226 40.525 18.6005 37.8995C15.975 35.274 14.5 31.713 14.5 28C14.5 24.287 15.975 20.726 18.6005 18.1005C21.226 15.475 24.787 14 28.5 14C32.213 14 35.774 15.475 38.3995 18.1005C41.025 20.726 42.5 24.287 42.5 28C42.5 31.713 41.025 35.274 38.3995 37.8995C35.774 40.525 32.213 42 28.5 42ZM28.5 44C32.7435 44 36.8131 42.3143 39.8137 39.3137C42.8143 36.3131 44.5 32.2435 44.5 28C44.5 23.7565 42.8143 19.6869 39.8137 16.6863C36.8131 13.6857 32.7435 12 28.5 12C24.2565 12 20.1869 13.6857 17.1863 16.6863C14.1857 19.6869 12.5 23.7565 12.5 28C12.5 32.2435 14.1857 36.3131 17.1863 39.3137C20.1869 42.3143 24.2565 44 28.5 44Z"
                    fill="#F67554"
                  />
                  <path
                    d="M30.3599 25.176L25.7799 25.75L25.6159 26.51L26.5159 26.676C27.1039 26.816 27.2199 27.028 27.0919 27.614L25.6159 34.55C25.2279 36.344 25.8259 37.188 27.2319 37.188C28.3219 37.188 29.5879 36.684 30.1619 35.992L30.3379 35.16C29.9379 35.512 29.3539 35.652 28.9659 35.652C28.4159 35.652 28.2159 35.266 28.3579 34.586L30.3599 25.176ZM30.4999 21C30.4999 21.5304 30.2892 22.0391 29.9141 22.4142C29.539 22.7893 29.0303 23 28.4999 23C27.9695 23 27.4608 22.7893 27.0857 22.4142C26.7106 22.0391 26.4999 21.5304 26.4999 21C26.4999 20.4696 26.7106 19.9609 27.0857 19.5858C27.4608 19.2107 27.9695 19 28.4999 19C29.0303 19 29.539 19.2107 29.9141 19.5858C30.2892 19.9609 30.4999 20.4696 30.4999 21Z"
                    fill="#F67554"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2567_14789">
                    <rect width="32" height="32" fill="white" transform="translate(12.5 12)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="transactions-empty-title">No transactions yet</div>
            <div className="transactions-empty-text">
              Start transacting with your wallet. All
              <br />
              transactions made will be displayed here.
            </div>
            <Button
              className="transactions-empty-button"
              title="Deposit"
              color="orange"
              clickHandler={() => console.log('Deposit')}
            />
          </>
        }
      </div>
    </div>
  );
};

export default TransactionsEmpty;
