import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosInstance } from 'axios';

import { RootState } from '..';
import { IBalanceHistory, IBalanceHistoryState } from 'type';

export const getBalanceHistory = createAsyncThunk<IBalanceHistory, void, { extra: AxiosInstance }>(
  'balance-history/get', async (_, { getState, extra, rejectWithValue }
) => {
  try {
    const res = await extra.get('/api/balance-history');
    return res.data as IBalanceHistory;
  } catch (err) {
    const error = err as AxiosError;
    return rejectWithValue(error?.response?.statusText);
  }
});

const initialState: IBalanceHistoryState = {
  loading: false,
  error: null,
  data: []
};

export const balanceHistorySlice = createSlice({
  name: 'balanceHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBalanceHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBalanceHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.data = Object.entries(action.payload).map(([name, uv]) => ({ name, uv }));
      })
  },
});

export const selectBalanceHistory = (state: RootState) => state.balanceHistory;
export const selectBalanceHistoryItemCount = (state: RootState) => Object.keys(state.balanceHistory.data).length;
export const selectIsChartAvailable = (state: RootState) => Object.values(state.balanceHistory.data)[0];

export default balanceHistorySlice.reducer;
