import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosInstance } from 'axios';

import { RootState } from '..';
import type { CoinState, ICoin } from 'type';

export const getCoins = createAsyncThunk<Array<ICoin>, void, { extra: AxiosInstance }>('coins/get', async (_, { getState, extra, rejectWithValue }) => {
  try {
    const res = await extra.get(`/api/currency-value?name=${['bitcoin', 'ethereum', 'solana', 'dogecoin'].toString()}`);
    const arrayOfCoins: ICoin[] = [];
      for (const key of Object.keys(res.data)) {
        arrayOfCoins.push({
          price: res.data[key].usd,
          symbol: key,
          usd_24h_change: res.data[key].usd_24h_change,
        });
      }
      return arrayOfCoins;
  } catch (err) {
    const error = err as AxiosError;
    return rejectWithValue(error?.response?.statusText);
  }
});

const initialState: CoinState = {
  list: []
};

export const coinsSlice = createSlice({
  name: 'coins',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCoins.fulfilled, (state, action) => {
        state.list = action.payload;
      })
  },
});

export const selectCoins = (state: RootState) => state.coins;

export default coinsSlice.reducer;
