import React, { useState } from 'react';

import { TransactionProps } from '../types';
import { Button } from 'components';
import { ReactComponent as Clippy } from 'assets/images/icons/clippy.svg';
import { ReactComponent as Check } from 'assets/images/icons/check.svg';

const MobileTransactionItem: React.FC<TransactionProps> = ({ transaction }) => {
  const [isCopiedFrom, setIsCopiedFrom] = useState(false);
  const [isCopiedHash, setIsCopiedHash] = useState(false);

  const handleCopyFrom = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setIsCopiedFrom(true);
      setTimeout(() => setIsCopiedFrom(false), 1000);
    });
  };

  const handleCopyHash = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setIsCopiedHash(true);
      setTimeout(() => setIsCopiedHash(false), 1000);
    });
  };

  const getMoreDetailsLink = (asset: string) => {
    switch (asset) {
      case 'ETH':
        return 'https://goerli.etherscan.io/tx/';
      case 'BTC':
        return 'https://live.blockcypher.com/btc-testnet/tx/';
      case 'TRX':
        return 'https://shasta.tronscan.org/#/transaction/';
      default:
        return 'https://goerli.etherscan.io/tx/';
    }
  };

  return (
    <div className="mobile-history-block-items">
      <div>
        <span className="mobile-history-block-items-title">Time</span>
        <span>{`${transaction?.date?.slice(0, 10) || '---'} ${transaction?.date?.slice(12, 16) || '---'}`}</span>
      </div>
      <div>
        <span className="mobile-history-block-items-title">Type</span>
        <span>{transaction?.type}</span>
      </div>
      <div>
        <span className="mobile-history-block-items-title">Coin</span>
        <span>{transaction?.asset}</span>
      </div>
      <div>
        <span className="mobile-history-block-items-title">Amount</span>
        <span className="mobile-history-amount">{transaction?.value?.toString().slice(0, 5)}</span>
      </div>
      <div>
        <span className="mobile-history-block-items-title">Destination</span>
        <span>
          <span className="copy-container">
            {`${transaction?.from?.slice(0, 5) || ''}...${transaction?.from?.slice(-5) || ''}`}
            <button onClick={() => handleCopyFrom(transaction?.from)}>
              {isCopiedFrom ? (
                <Check
                  style={{
                    color: 'green',
                    strokeDasharray: 50,
                    strokeDashoffset: isCopiedFrom ? 0 : -50,
                  }}
                />
              ) : (
                <Clippy
                  style={{
                    color: 'grey',
                    strokeDasharray: 50,
                    strokeDashoffset: isCopiedFrom ? -50 : 0,
                  }}
                />
              )}
            </button>
          </span>
        </span>
      </div>
      <div>
        <span className="mobile-history-block-items-title">TxID</span>
        <span>
          <span className="copy-container">
            {`${transaction?.hash?.slice(0, 5) || ''}...${transaction?.hash?.slice(-5) || ''}`}
            <button onClick={() => handleCopyHash(transaction?.hash)}>
              {isCopiedHash ? (
                <Check
                  style={{
                    color: 'green',
                    strokeDasharray: 50,
                    strokeDashoffset: isCopiedHash ? 0 : -50,
                  }}
                />
              ) : (
                <Clippy
                  style={{
                    color: 'grey',
                    strokeDasharray: 50,
                    strokeDashoffset: isCopiedHash ? -50 : 0,
                  }}
                />
              )}
            </button>
          </span>
        </span>
      </div>
      <div>
        <span className="mobile-history-block-items-title">Status</span>
        <span>Completed</span>
      </div>
      <Button
        title="More"
        color="black"
        clickHandler={() => window.location.assign(`${getMoreDetailsLink(transaction.asset)}${transaction?.hash}`)}
        className="table-container-buttons-mobile"
      />
    </div>
  );
};

export default MobileTransactionItem;
