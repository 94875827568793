import React from 'react';

import './Spinner.scss';

interface ISpinnerProps {
  size?: 'sm' | 'md' | 'lg';
  isCentered?: boolean;
  className?: string;
}

const Spinner: React.FC<ISpinnerProps> = ({ size = 'md', isCentered, className='' }) => {
  return (
    <div className={`Spinner ${size} ${isCentered ? 'Spinner-centered' : ''} ${className}`}>
      <div className="Spinner__circle" />
    </div>
  );
};

export default Spinner;
