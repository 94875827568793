import React from 'react';

import './FAQ.scss';

export const FAQ = () => {
    return (
        <div className="faq">
            <div className="faq-container">
                <div className="faq-container-section">
                    <h1 className="faq-container-section-title">Hello, how can we help you?</h1>
                    <div className="faq-container-section-input-container">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_907_10709)">
                                <g clip-path="url(#clip1_907_10709)">
                                    <path d="M17.8897 15.5161C19.3422 13.5342 19.9927 11.0769 19.7112 8.63587C19.4297 6.19486 18.2369 3.95016 16.3714 2.35085C14.5059 0.751532 12.1054 -0.0844439 9.64999 0.0101658C7.19462 0.104776 4.86551 1.12299 3.12864 2.86111C1.39176 4.59923 0.375213 6.92907 0.28236 9.3845C0.189508 11.8399 1.0272 14.2399 2.62785 16.1042C4.2285 17.9685 6.47405 19.1598 8.91527 19.4395C11.3565 19.7193 13.8133 19.067 15.7942 17.6131H15.7927C15.8377 17.6731 15.8857 17.7302 15.9397 17.7857L21.7147 23.5606C21.996 23.8421 22.3776 24.0003 22.7755 24.0005C23.1734 24.0006 23.555 23.8427 23.8365 23.5614C24.1179 23.2801 24.2762 22.8986 24.2763 22.5007C24.2764 22.1028 24.1185 21.7211 23.8372 21.4397L18.0622 15.6646C18.0086 15.6104 17.9509 15.5602 17.8897 15.5147V15.5161ZM18.2767 9.75015C18.2767 10.8336 18.0633 11.9064 17.6487 12.9073C17.2341 13.9082 16.6265 14.8177 15.8604 15.5838C15.0943 16.3499 14.1848 16.9576 13.1839 17.3722C12.1829 17.7868 11.1101 18.0002 10.0267 18.0002C8.94333 18.0002 7.87054 17.7868 6.8696 17.3722C5.86866 16.9576 4.95919 16.3499 4.19311 15.5838C3.42702 14.8177 2.81933 13.9082 2.40473 12.9073C1.99013 11.9064 1.77674 10.8336 1.77674 9.75015C1.77674 7.56211 2.64593 5.4637 4.19311 3.91652C5.74028 2.36935 7.8387 1.50015 10.0267 1.50015C12.2148 1.50015 14.3132 2.36935 15.8604 3.91652C17.4075 5.4637 18.2767 7.56211 18.2767 9.75015Z" fill="#F67554" />
                                </g>
                            </g>
                            <defs>
                                <clipPath id="clip0_907_10709">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                                <clipPath id="clip1_907_10709">
                                    <rect width="24" height="24" fill="white" transform="translate(0.277344)" />
                                </clipPath>
                            </defs>
                        </svg>
                        <input placeholder="What do you want to know?" type="text" className="faq-container-section-input" />
                    </div>
                </div>
                <div className="faq-container-section">
                    <h1 className="faq-container-section-title __secondary">Most popular questions</h1>
                    <div className="faq-container-section-block" id="border-top">
                        <div className="faq-container-section-block-element">
                            <h3>What does Coinbooster do with my digital assets?</h3>
                            <p>
                                When you buy, receive, or hold digital assets using a Coinbooster.me account, they are securely stored
                                or 'custodied' for your benefit in a hosted digital asset wallet. At all times, these assets are yours -
                                they never belong to Coinbooster.
                            </p>
                        </div>
                        <div className="faq-container-section-block-element">
                            <h3>My account was compromised</h3>
                            <p>See these steps to secure your account ifyou notice suspicious activity.</p>
                        </div>
                        <div className="faq-container-section-block-element">
                            <h3>How to verify your identity on Coinbooster</h3>
                            <p>Tips and steps for verifying your identity documents with Coinbooster.</p>
                        </div>
                    </div>
                    <div className="faq-container-section-block" id="border-top">
                        <div className="faq-container-section-block-element">
                            <h3>Unsupported Crypto Recovery</h3>
                            <p>
                                Learn about what happens to unsupported crypto sent to Coinbooster and crypto sent to Coinbooster on the
                                wrong network.
                            </p>
                        </div>
                        <div className="faq-container-section-block-element">
                            <h3>How to verify your identity on Coinbooster</h3>
                            <p>Tips and steps for verifying your identity documents with Coinbooster.</p>
                        </div>
                        <div className="faq-container-section-block-element">
                            <h3>What does Coinbooster do with my digital assets?</h3>
                            <p>
                                When you buy, receive, or hold digital assets using a Coinbooster.me account, they are securely stored
                                or 'custodied' for your benefit in a hosted digital asset wallet. At all times, these assets are yours -
                                they never belong to Coinbooster.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="faq-container-section">
                    <h1 className="faq-container-section-title __secondary">Contact us</h1>
                    <div className="faq-container-section-block-buttons" id="border-top">
                        <button className="faq-button">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2685_6117)">
                                    <path d="M0.075 5.3325C0.226227 4.66986 0.597983 4.07822 1.12938 3.65446C1.66078 3.23071 2.32033 2.99995 3 3H21C21.6797 2.99995 22.3392 3.23071 22.8706 3.65446C23.402 4.07822 23.7738 4.66986 23.925 5.3325L12 12.621L0.075 5.3325ZM0 7.0455V17.7015L8.7045 12.3645L0 7.0455ZM10.1415 13.245L0.2865 19.2855C0.52999 19.7989 0.91429 20.2326 1.39467 20.5362C1.87505 20.8397 2.43176 21.0005 3 21H21C21.5681 21.0001 22.1246 20.8389 22.6048 20.5351C23.0849 20.2313 23.4688 19.7975 23.712 19.284L13.857 13.2435L12 14.379L10.1415 13.2435V13.245ZM15.2955 12.366L24 17.7015V7.0455L15.2955 12.3645V12.366Z" fill="#F67554" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2685_6117">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            Send us an email
                        </button>
                        <button className="faq-button">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12ZM12.4305 8.859C11.2635 9.345 8.9295 10.35 5.4315 11.874C4.8645 12.099 4.566 12.321 4.539 12.537C4.494 12.9015 4.9515 13.0455 5.574 13.242L5.8365 13.3245C6.4485 13.524 7.2735 13.7565 7.701 13.7655C8.091 13.7745 8.5245 13.6155 9.003 13.2855C12.2715 11.079 13.959 9.9645 14.064 9.9405C14.139 9.9225 14.244 9.9015 14.313 9.9645C14.3835 10.026 14.376 10.1445 14.3685 10.176C14.3235 10.3695 12.528 12.0375 11.5995 12.9015C11.31 13.1715 11.1045 13.362 11.0625 13.4055C10.97 13.5 10.876 13.593 10.7805 13.6845C10.2105 14.2335 9.7845 14.6445 10.803 15.3165C11.2935 15.6405 11.6865 15.906 12.078 16.173C12.504 16.464 12.93 16.7535 13.482 17.1165C13.6215 17.2065 13.7565 17.304 13.887 17.397C14.3835 17.751 14.832 18.069 15.3825 18.018C15.7035 17.988 16.035 17.688 16.203 16.788C16.6005 14.6625 17.382 10.059 17.562 8.1615C17.573 8.00376 17.5664 7.84529 17.5425 7.689C17.5284 7.56288 17.4673 7.44669 17.3715 7.3635C17.235 7.26928 17.0723 7.22047 16.9065 7.224C16.4565 7.2315 15.762 7.473 12.4305 8.859Z" fill="#F67554" />
                            </svg>
                            Write to us on telegram
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
