import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components';
import { ReactComponent as Info } from 'assets/images/icons/info.svg';
import apiReq from 'store/api';

interface FormValues {
  password: string;
  passwordConfirm: string;
}

export const ResetPasswordConfirm = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm<FormValues>();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const handleSuccess = (res: any) => {
    localStorage.setItem('Access-Token', res.data.access_token);
    navigate('/dashboard');
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    try {
      const res = await apiReq.post(
        `/api/profile/reset-password/confirm/${code}`,
        { password: data.password, password_confirm: data.passwordConfirm }
      );
      handleSuccess(res);
    } catch (error: any) {
      setError(error.response.data['error']);
    }
  };

  return (
    <form className="signup-container-form" onSubmit={handleSubmit(onSubmit)}>
      <h1 className="signup-container-form-title">Enter new password</h1>
      <div className="input-wrapper">
        <label className="signup-container-form-subtitle" htmlFor="password">
          New password
        </label>
        <input
          id="password"
          type="password"
          placeholder="Enter new password"
          {...register('password', {
            required: 'This field is required',
            minLength: {
              value: 5,
              message: 'Password must be at least 5 characters long'
            }
          })}
          className="signup-container-form-input"
        />
        <span className={`color-orange font-sm ${errors.password ? 'block' : 'none'}`}>
          <span className="mr-1">
            <Info />
          </span>
          {errors.password?.message}
        </span>
      </div>
      <div className="input-wrapper">
        <label className="signup-container-form-subtitle" htmlFor="passwordConfirm">
          Repeat password
        </label>
        <input
          id="passwordConfirm"
          type="password"
          placeholder="Repeat password"
          {...register('passwordConfirm', {
            required: 'This field is required',
            validate: (val: string) => {
              if (watch('password') !== val) return "Your passwords do not match";
            }
          })}
          className="signup-container-form-input"
        />
        {error ? <span className="form-error">{error}</span> : null}
        <span className={`color-orange font-sm ${errors.passwordConfirm ? 'block' : 'none'}`}>
          <span className="mr-1">
            <Info />
          </span>
          {errors.passwordConfirm?.message}
        </span>
      </div>
      <Button title="Confirm" color="orange" type="submit" className="signup-container-form-submit" />
    </form>
  );
};
