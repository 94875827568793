import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toggleNavigation, selectNavigation } from 'store/features/uiSlice';

import { Drawer } from '@mui/material';
import { Button } from 'components';

import help from 'assets/images/help.svg';
import power from 'assets/images/power.png';
import wallet from 'assets/images/wallet.svg';
import profile from 'assets/images/profile.png';
// import exchange from 'assets/images/exchange.svg';
import transactions from 'assets/images/transactions.svg';
import { useAppSelector, useAppDispatch } from 'hooks';
import './MobileMenu.scss';

const navElements = [
  { title: 'Wallet', url: '/dashboard', img: wallet },
  { title: 'Transactions', url: '/transactions', img: transactions },
  // { title: 'Exchange', url: '', img: exchange },
  { title: 'Help center', url: '/help', img: help },
];

export const MobileMenu = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectNavigation);

  const navigate = useNavigate();

  const handleCloseDrawer = () => {
    dispatch(toggleNavigation());
  };

  const handleLogout = () => {
    handleCloseDrawer();

    localStorage.removeItem('Access-Token');
    localStorage.removeItem('remember-me');

    navigate('/login');
  };

  return (
    <Drawer anchor={'top'} open={isOpen}>
      <div className="menu-mobile">
        <hr />
        <div className="menu-mobile-buttons">
          <Button
            title="Buy"
            color="orange"
            clickHandler={() => {
              handleCloseDrawer();
              navigate('/buy');
            }}
          />
          <Button
            title="Sell"
            color="white"
            clickHandler={() => {
              handleCloseDrawer();
              navigate('/send');
            }}
          />
        </div>
        <hr />
        <div className="menu-mobile-dashboard">
          <h3>DASHBOARD</h3>
          <ul className="navigation-list">
            {navElements.map((el, i) => (
              <li key={i} onClick={handleCloseDrawer}>
                <Link to={el.url}>
                  {/* TODO: change svg image color on hover */}
                  <img src={el.img} alt={el.title} />
                  {el.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <hr />
        <div className="menu-mobile-profile">
          <h3>PROFILE</h3>
          <div className="profile-list">
            <div
              className="profile-list-item"
              onClick={() => {
                handleCloseDrawer();
                navigate('/profile');
              }}
            >
              <img src={profile} alt="profile" />
              <span>Profile</span>
            </div>
            <div className="profile-list-item" onClick={handleLogout}>
              <img src={power} alt="profile" />
              <span>Log out</span>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
