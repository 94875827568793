import React from 'react';
import { Link } from 'react-router-dom';

import logo from 'assets/images/logo/logo.svg';
import './Navigation.scss';

export const Navigation = () => {
  return (
    <div className="navigation">
      <span className="logo-container">
        <Link to="/dashboard">
          <img
            className="logo-container-img"
            src={logo}
            alt="logo"
          />
        </Link>
      </span>
      <div className="navigation-container">
        <ul className="navigation-list">
          <li className={`${window.location.pathname === '/dashboard' ? 'active' : ''}`}>
            <Link to="/dashboard">
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H15.5C15.6326 0 15.7598 0.0526784 15.8536 0.146447C15.9473 0.240215 16 0.367392 16 0.5C16 0.632608 15.9473 0.759785 15.8536 0.853553C15.7598 0.947322 15.6326 1 15.5 1H15V3C15.2652 3 15.5196 3.10536 15.7071 3.29289C15.8946 3.48043 16 3.73478 16 4V12.5C16 12.8978 15.842 13.2794 15.5607 13.5607C15.2794 13.842 14.8978 14 14.5 14H2.5C1.83696 14 1.20107 13.7366 0.732233 13.2678C0.263392 12.7989 0 12.163 0 11.5L0 2ZM1 3.732V11.5C1 11.8978 1.15804 12.2794 1.43934 12.5607C1.72064 12.842 2.10218 13 2.5 13H14.5C14.6326 13 14.7598 12.9473 14.8536 12.8536C14.9473 12.7598 15 12.6326 15 12.5V4H2C1.64886 4.00046 1.30385 3.90799 1 3.732ZM1 2C1 2.26522 1.10536 2.51957 1.29289 2.70711C1.48043 2.89464 1.73478 3 2 3H14V1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2Z"
                  fill="currentColor"
                />
              </svg>
              Wallet
            </Link>
          </li>
          <li className={`${window.location.pathname === '/transactions' ? 'active' : ''}`}>
            <Link to="/transactions">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.515 1.019C8.34363 1.00635 8.17184 1.00001 8 1V0C8.19654 9.58276e-05 8.393 0.00743414 8.589 0.022L8.515 1.019ZM10.519 1.469C10.1984 1.3453 9.86922 1.24537 9.534 1.17L9.753 0.194C10.136 0.28 10.513 0.394 10.879 0.536L10.519 1.469ZM11.889 2.179C11.746 2.08365 11.5996 1.99359 11.45 1.909L11.943 1.039C12.2849 1.23274 12.6121 1.45132 12.922 1.693L12.307 2.482C12.1714 2.37623 12.032 2.2755 11.889 2.18V2.179ZM13.723 3.969C13.5252 3.68798 13.3069 3.42192 13.07 3.173L13.794 2.483C14.064 2.768 14.314 3.073 14.541 3.393L13.723 3.969ZM14.467 5.321C14.4014 5.16246 14.33 5.00636 14.253 4.853L15.146 4.403C15.3226 4.75409 15.473 5.11774 15.596 5.491L14.646 5.804C14.5923 5.64087 14.5326 5.47976 14.467 5.321ZM14.997 7.828C14.9889 7.48434 14.9555 7.14174 14.897 6.803L15.882 6.633C15.949 7.019 15.988 7.411 15.998 7.803L14.998 7.828H14.997ZM14.866 9.366C14.899 9.196 14.926 9.027 14.947 8.856L15.94 8.979C15.892 9.36915 15.8151 9.75521 15.71 10.134L14.746 9.867C14.792 9.702 14.832 9.535 14.866 9.366ZM13.914 11.745C14.098 11.455 14.26 11.151 14.4 10.837L15.314 11.242C15.154 11.602 14.969 11.948 14.759 12.28L13.914 11.745ZM12.95 12.95C13.072 12.828 13.189 12.702 13.3 12.572L14.058 13.225C13.9296 13.3738 13.7959 13.5179 13.657 13.657L12.95 12.95Z"
                  fill="currentColor"
                />
                <path
                  d="M8.00019 1C6.84907 1.00008 5.71574 1.28405 4.70057 1.82674C3.68541 2.36943 2.81974 3.1541 2.18025 4.11125C1.54076 5.0684 1.14719 6.16848 1.03439 7.31406C0.921589 8.45964 1.09304 9.61536 1.53357 10.6789C1.9741 11.7423 2.6701 12.6808 3.55992 13.4111C4.44974 14.1413 5.50593 14.6409 6.63493 14.8655C7.76393 15.0901 8.93089 15.0328 10.0325 14.6987C11.134 14.3645 12.1362 13.7639 12.9502 12.95L13.6572 13.657C12.7269 14.5878 11.5814 15.2747 10.3222 15.657C9.06302 16.0393 7.72896 16.105 6.43826 15.8485C5.14755 15.5919 3.94005 15.021 2.92274 14.1862C1.90543 13.3515 1.10973 12.2787 0.606129 11.0629C0.102528 9.84711 -0.0934196 8.52588 0.0356482 7.21627C0.164716 5.90666 0.614814 4.6491 1.34606 3.55502C2.07731 2.46094 3.06713 1.56411 4.22782 0.944003C5.38851 0.323895 6.68424 -0.000348732 8.00019 2.81458e-07V1Z"
                  fill="currentColor"
                />
                <path
                  d="M7.5 3C7.63261 3 7.75979 3.05268 7.85355 3.14645C7.94732 3.24021 8 3.36739 8 3.5V8.71L11.248 10.566C11.3598 10.6334 11.4408 10.7419 11.4736 10.8683C11.5065 10.9946 11.4886 11.1288 11.4238 11.2422C11.3591 11.3556 11.2525 11.4391 11.127 11.4749C11.0014 11.5108 10.8669 11.4961 10.752 11.434L7.252 9.434C7.17547 9.39029 7.11186 9.32712 7.06761 9.25091C7.02335 9.17469 7.00003 9.08813 7 9V3.5C7 3.36739 7.05268 3.24021 7.14645 3.14645C7.24021 3.05268 7.36739 3 7.5 3Z"
                  fill="currentColor"
                />
              </svg>
              Transactions
            </Link>
          </li>
          {/* <li className={`${window.location.pathname === '/' ? 'active' : ''}`}>
            <Link to="">
              <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M-8.27909e-05 11.5006C-8.27909e-05 11.6332 0.0525956 11.7604 0.146364 11.8542C0.240132 11.948 0.367309 12.0006 0.499917 12.0006H12.2929L9.14592 15.1466C9.05203 15.2405 8.99929 15.3679 8.99929 15.5006C8.99929 15.6334 9.05203 15.7607 9.14592 15.8546C9.2398 15.9485 9.36714 16.0013 9.49992 16.0013C9.63269 16.0013 9.76003 15.9485 9.85392 15.8546L13.8539 11.8546C13.9005 11.8082 13.9374 11.753 13.9626 11.6923C13.9878 11.6315 14.0008 11.5664 14.0008 11.5006C14.0008 11.4349 13.9878 11.3697 13.9626 11.309C13.9374 11.2483 13.9005 11.1931 13.8539 11.1466L9.85392 7.14663C9.76003 7.05274 9.63269 7 9.49992 7C9.36714 7 9.2398 7.05274 9.14592 7.14663C9.05203 7.24052 8.99929 7.36786 8.99929 7.50063C8.99929 7.63341 9.05203 7.76075 9.14592 7.85463L12.2929 11.0006H0.499917C0.367309 11.0006 0.240132 11.0533 0.146364 11.1471C0.0525956 11.2408 -8.27909e-05 11.368 -8.27909e-05 11.5006ZM13.9999 4.50063C13.9999 4.63324 13.9472 4.76042 13.8535 4.85419C13.7597 4.94795 13.6325 5.00063 13.4999 5.00063H1.70692L4.85392 8.14663C4.9004 8.19312 4.93728 8.24831 4.96244 8.30905C4.9876 8.36979 5.00055 8.43489 5.00055 8.50063C5.00055 8.56637 4.9876 8.63148 4.96244 8.69221C4.93728 8.75295 4.9004 8.80814 4.85392 8.85463C4.80743 8.90112 4.75224 8.938 4.6915 8.96315C4.63076 8.98831 4.56566 9.00126 4.49992 9.00126C4.43417 9.00126 4.36907 8.98831 4.30833 8.96315C4.24759 8.938 4.1924 8.90112 4.14592 8.85463L0.145917 4.85463C0.099354 4.80819 0.0624111 4.75301 0.0372047 4.69227C0.0119982 4.63152 -0.000976563 4.5664 -0.000976562 4.50063C-0.000976563 4.43486 0.0119982 4.36974 0.0372047 4.309C0.0624111 4.24825 0.099354 4.19308 0.145917 4.14663L4.14592 0.146632C4.2398 0.052745 4.36714 0 4.49992 0C4.63269 0 4.76003 0.052745 4.85392 0.146632C4.9478 0.240518 5.00055 0.367856 5.00055 0.500632C5.00055 0.633407 4.9478 0.760745 4.85392 0.854632L1.70692 4.00063H13.4999C13.6325 4.00063 13.7597 4.05331 13.8535 4.14708C13.9472 4.24085 13.9999 4.36802 13.9999 4.50063Z"
                  fill="currentColor"
                />
              </svg>
              Exchange
            </Link>
          </li> */}
          <li className={`${window.location.pathname === '/help' ? 'active' : ''}`}>
            <Link to="/help">
              <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6 1C4.67392 1 3.40215 1.52678 2.46447 2.46447C1.52678 3.40215 1 4.67392 1 6V7H2C2.26522 7 2.51957 7.10536 2.70711 7.29289C2.89464 7.48043 3 7.73478 3 8V11C3 11.2652 2.89464 11.5196 2.70711 11.7071C2.51957 11.8946 2.26522 12 2 12H1C0.734784 12 0.48043 11.8946 0.292893 11.7071C0.105357 11.5196 0 11.2652 0 11V6C-1.17411e-08 5.21207 0.155195 4.43185 0.456723 3.7039C0.758251 2.97595 1.20021 2.31451 1.75736 1.75736C2.31451 1.20021 2.97595 0.758251 3.7039 0.456723C4.43185 0.155195 5.21207 0 6 0C6.78793 0 7.56815 0.155195 8.2961 0.456723C9.02405 0.758251 9.68549 1.20021 10.2426 1.75736C10.7998 2.31451 11.2417 2.97595 11.5433 3.7039C11.8448 4.43185 12 5.21207 12 6V12C12 12.663 11.7366 13.2989 11.2678 13.7678C10.7989 14.2366 10.163 14.5 9.5 14.5H7.366C7.27823 14.652 7.152 14.7783 6.99999 14.866C6.84797 14.9538 6.67553 15 6.5 15H5.5C5.23478 15 4.98043 14.8946 4.79289 14.7071C4.60536 14.5196 4.5 14.2652 4.5 14C4.5 13.7348 4.60536 13.4804 4.79289 13.2929C4.98043 13.1054 5.23478 13 5.5 13H6.5C6.67553 13 6.84797 13.0462 6.99999 13.134C7.152 13.2217 7.27823 13.348 7.366 13.5H9.5C9.89782 13.5 10.2794 13.342 10.5607 13.0607C10.842 12.7794 11 12.3978 11 12H10C9.73478 12 9.48043 11.8946 9.29289 11.7071C9.10536 11.5196 9 11.2652 9 11V8C9 7.73478 9.10536 7.48043 9.29289 7.29289C9.48043 7.10536 9.73478 7 10 7H11V6C11 5.34339 10.8707 4.69321 10.6194 4.08658C10.3681 3.47995 9.99983 2.92876 9.53553 2.46447C9.07124 2.00017 8.52004 1.63188 7.91342 1.3806C7.30679 1.12933 6.65661 1 6 1Z"
                  fill="currentColor"
                />
              </svg>
              Help center
            </Link>
          </li>
          {/* {navElements.map((el, i) => (
                        <li key={i} className={`${window.location.pathname === el.url ? 'active' : ''}`}>
                            <Link to={el.url}>
                                <img src={el.img} alt={el.title} />
                                {el.title}
                            </Link>
                        </li>
                    ))} */}
        </ul>
      </div>
    </div>
  );
};
