import React from 'react';

import { Table } from 'components';
import TransactionItem from './TransactionItem';
import MobileTransactionItem from './MobileTransactionItem';
import TransactionsEmpty from './TransactionsEmpty';
import { Transaction } from '../types';

type HistoryProps = {
  history: Transaction[];
  isLoading: boolean;
};

const History: React.FC<HistoryProps> = ({ history, isLoading }) => {
  return (
    <Table title="Transaction History" buttons={<></>} classname="transac">
      {history.length > 0 && (
        <>
        <table className="history">
            <tr>
              <th>Time</th>
              <th className="narrow-cell">Type</th>
              <th className="narrow-cell">Coin</th>
              <th>Amount</th>
              <th className="hide">Destination</th>
              <th className="hide">TxID</th>
              <th>Status</th>
              <th></th>
            </tr>
            <tbody>
              {history.map((transaction) => (
                <TransactionItem key={transaction.hash} transaction={transaction} />
              ))}
            </tbody>
          </table>
          <div className="mobile-history">
            {history.map((transaction) => (
              <div className="mobile-history-block">
                {/* <div className="mobile-history-block-items"> */}
                <MobileTransactionItem key={transaction.hash} transaction={transaction} />
                {/* </div> */}
              </div>
            ))}
          </div>
        </>
      )}
      {
        !history.length ?
        <TransactionsEmpty isLoading={isLoading} />
        : null
      }
    </Table>
  );
};

export default History;
