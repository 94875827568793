import React from 'react';

import { Button } from '..';
import visa from '../../assets/images/visa.png';
import './Suggestion.scss';

export const Suggestion: React.FC = () => {
  return (
    <div className="suggestions">
      <div className="suggestions-element __deposit">
        <img src={visa} alt="visa" />
        <span className="suggestions-element-title" id="container">
          Deposit using VISA/MC
        </span>
        <span className="suggestions-element-helper" id="container">
          Conveniently and securely recharge your account with VISA/Mastercard.
        </span>
        <div className="suggestions-element-buttons">
          <Button title="Deposit" color="orange" clickHandler={() => console.log('Deposit')} />
          <Button title="Hide" color="white" clickHandler={() => console.log('Hide')} />
        </div>
      </div>
    </div>
  );
};
