import React from 'react';
import { Navigate } from 'react-router-dom';

import { LayoutProps } from './type';
import { Layout } from './layout';

const ProtectedLayout: React.FC<LayoutProps> = ({ title, children }) => {
  if (!localStorage.getItem('Access-Token')) return <Navigate to="/login" replace />;

  return (
    <Layout title={title}>
      {children}
    </Layout>
  );
};

export default ProtectedLayout;
