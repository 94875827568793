import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosInstance } from 'axios';

import { RootState } from '..';
import { AssetState, IAsset } from 'type';

export const getAssets = createAsyncThunk<Array<IAsset>, void, { extra: AxiosInstance }>(
  'user/get',
  async (_, { getState, extra, rejectWithValue }) => {
    try {
      const res = await extra.get(`/api/wallets`);

      const arrayOfAssets = res.data.map((wallet: any) => {
        return {
          img: wallet.wallets.image,
          name: wallet.wallets.name,
          ticker: wallet.wallets.ticker,
          network: wallet.wallets.network,
        };
      });

      return arrayOfAssets;
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.response?.statusText);
    }
  }
);

const initialState: AssetState = {
  list: [],
};

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAssets.fulfilled, (state, action) => {
      state.list = action.payload;
    });
  },
});

export const selectAssets = (state: RootState) => state.assets;

export default assetsSlice.reducer;
