import React, { useState } from 'react';

import { ReactComponent as Success } from 'assets/images/icons/success-ico.svg';
import { ReactComponent as Fail } from 'assets/images/icons/fail-ico.svg';
import { ReactComponent as Copy } from "assets/images/icons/copy.svg";
import { ReactComponent as Check } from 'assets/images/icons/check.svg';
import { ReactComponent as Chain } from 'assets/images/icons/chain.svg';
import './PaymentResult.scss';
import { Link } from "react-router-dom";
import { Button } from "components";

type Props = {
  isSuccessful?: boolean;
} 

const PaymentResult: React.FC<Props> = ({ isSuccessful }) => {
  const [isCopiedAddress, setIsCopiedAddress] = useState(false);
  const [isCopiedId, setIsCopiedId] = useState(false);

  const handleCopyAddress = (value: string, setStatus: (val: boolean) => void) => {
    navigator.clipboard.writeText(value).then(() => {
      setStatus(true);
      setTimeout(() => setStatus(false), 1500);
    });
  };

  return (
    <div className="Payment send">
      <div className="send-container pt-3 pr-3 pl-3 pb-3">
        <div className="Payment__header w-100 flex flex-column justify-center align-center pb-2">
          <div className="mb-1">
            {
              isSuccessful ?
              <Success />
              :
              <Fail />
            }
          </div>
          <h2 className={`m-0 font-lg ${isSuccessful ? 'color-green' : 'color-red'}`}>
            Payment success
          </h2>
        </div>
        <div className="Payment__body w-100">
          <div className="flex flex-column aling-center mb-2">
            <p className="color-gray font-sm mt-2 mb-1 text-center">
              Amount
            </p>
            <div className="Payment__money-container flex justify-center align-center">
              <div className="font-xxl m-0">
                1610
              </div>
              <div className="font-sm ml-1">
                usdt
              </div>
            </div>
          </div>
          <ul>
            <li className="Payment__row">
              <p className="m-0 font-sm color-gray">
                Network
              </p>
              <p className="m-0 font-sm">
                ETH
              </p>
            </li>
            <li className="Payment__row">
              <p className="m-0 font-sm color-gray">
                Date & Time
              </p>
              <p className="m-0 font-sm">
                2022-05-19 19:08:18
              </p>
            </li>
            <li className="Payment__row">
              <p className="m-0 font-sm color-gray">
                Withdrawl wallet
              </p>
              <p className="m-0 font-sm">
                Wallet
              </p>
            </li>
            <li className="Payment__row">
              <p className="m-0 font-sm color-gray">
                Address
              </p>
              <div className="flex">
                <p className="m-0 font-sm">
                  0x39484cad5361b51f204e41147c96b941993f45da
                </p>
                <div className="flex flex-column ml-2">
                  {
                    isCopiedAddress ?
                    <Check 
                      stroke="#5BD548" 
                      height={20}
                      width={20}
                    />
                    :
                    <button 
                      className="btn-reset" 
                      onClick={() => handleCopyAddress('address', setIsCopiedAddress)}
                    >
                      <Copy 
                        height={20}
                        width={20}
                      />
                    </button>
                  }
                  <a className="block" href="/#">
                    <Chain
                      width={20}
                      height={20}
                    />
                  </a>
                </div>
              </div>
            </li>
            <li className="Payment__row">
              <p className="m-0 font-sm color-gray">
                TxID
              </p>
              <div className="flex">
                <p className="m-0 font-sm">
                  0x39484cad5361b51f204e41147c96b941993f45da
                </p>
                <div className="flex flex-column ml-2">
                  {
                    isCopiedId ?
                    <Check 
                      stroke="#5BD548" 
                      height={20}
                      width={20}
                    />
                    :
                    <button 
                      className="btn-reset" 
                      onClick={() => handleCopyAddress('address', setIsCopiedId)}
                    >
                      <Copy 
                        height={20}
                        width={20}
                      />
                    </button>
                  }
                  <a className="block" href="/#">
                    <Chain
                      width={20}
                      height={20}
                    />
                  </a>
                </div>
              </div>
            </li>
            <li className="Payment__row">
              <p className="m-0 font-sm color-gray">
                Network fee
              </p>
              <p className="m-0 font-sm">
                0.0001 ETH
              </p>
            </li>
          </ul>
          <div className="mt-2 mb-2 block text-center">
            <Link 
              to="/help"
              className="color-orange"
            >
              Need help? View FAQs
            </Link>
          </div>
        </div>
        <div className="Payment__footer w-100 pt-2">
          {
            isSuccessful ?
            <Button
              title="Continue"
              color="white"
              className="w-100 fw-500"
              isRounded
            />
            :
            <div className="flex">
              <Button
                title="Retry"
                color="orange"
                className="w-100 fw-500 mr-1"
                isRounded
              />
              <Button
                title="Continue"
                color="white"
                className="w-100 fw-500"
                isRounded
              />
            </div>
          }
          
        </div>
      </div>
    </div>
  );
};

export default PaymentResult;
