import React from "react";
import { AxiosResponse } from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router";

import google from 'assets/images/google.svg';
// import facebook from 'assets/images/facebook.svg';
import apiReq from "store/api";
import './SocialMediaSignIn.scss';

type Props = {
  onSuccess?: (...args: any[]) => void;
  onError: (message: string) => void;
}

export const SocialMediaSignIn: React.FC<Props> = ({ onSuccess, onError }) => {
  const navigate = useNavigate();

  const handleSuccess = (res: AxiosResponse) => {
    if (onSuccess) onSuccess();
    localStorage.setItem('Access-Token', res.data.access_token);
    navigate('/dashboard');
  };

  const handleGoogleSign = async(credentials: any) => {
    try {
      const res = await apiReq.post('/api/tokensignin', credentials);
      handleSuccess(res);
    } catch (err: any) {
      onError(err.response?.data?.['Error']);
    }
  };

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: (tokenResponse) => {
      handleGoogleSign(tokenResponse);
    },
  });

  return (
    <>
      <span className="signup-container-form-continue" onClick={() => googleLogin()}>
        <img src={google} alt="google" />
        Continue with Google
      </span>
      {/* <span className="signup-container-form-continue">
        <img src={facebook} alt="facebook" />
        Continue with Facebook
      </span> */}
    </ >
  );
};

export default SocialMediaSignIn;
