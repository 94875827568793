import React from 'react';
import { Outlet } from 'react-router-dom';
import { WagmiConfig } from 'wagmi';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react';
import { mainnet } from 'viem/chains';

const projectId = '05d33d7885ae5654b8c7b1264590bdd2';

const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
};

const chains = [mainnet];
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });
createWeb3Modal({ wagmiConfig, projectId, chains });

const WagmiProvider: React.FC = () => {
  return (
    <WagmiConfig config={wagmiConfig}>
      <Outlet />
    </WagmiConfig>
  );
};

export default WagmiProvider;
