import React, { useState } from 'react';

import { Button } from 'components';
import { ReactComponent as Clippy } from 'assets/images/icons/clippy.svg';
import { ReactComponent as Check } from 'assets/images/icons/check.svg';
import eye from 'assets/images/eye.png';
import { TransactionProps } from '../types';

const TransactionItem: React.FC<TransactionProps> = ({ transaction }) => {
  const [isCopiedFrom, setIsCopiedFrom] = useState(false);
  const [isCopiedHash, setIsCopiedHash] = useState(false);

  const handleCopyFrom = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setIsCopiedFrom(true);
      setTimeout(() => setIsCopiedFrom(false), 1000);
    });
  };

  const handleCopyHash = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setIsCopiedHash(true);
      setTimeout(() => setIsCopiedHash(false), 1000);
    });
  };

  const getMoreDetailsLink = (asset: string) => {
    switch (asset) {
      case 'ETH':
        if (process.env.REACT_APP_CHAIN === 'mainnet') {
          return 'https://etherscan.io/tx/';
        } else {
          return 'https://goerli.etherscan.io/tx/';
        }
      case 'BTC':
        if (process.env.REACT_APP_CHAIN === 'mainnet') {
          return 'https://live.blockcypher.com/btc/tx/';
        } else {
          return 'https://live.blockcypher.com/btc-testnet/tx/';
        }
      case 'TRX':
        return 'https://shasta.tronscan.org/#/transaction/';
      default:
        return 'https://goerli.etherscan.io/tx/';
    }
  };

  return (
    <tr className="grey">
      <td id="first">{`${transaction?.date?.slice(0, 10) || '---'} ${transaction?.date?.slice(12, 16) || '---'}`}</td>
      <td>{transaction.type}</td>
      <td>{transaction.asset}</td>
      <td>{transaction.value?.toString().slice(0, 5)}</td>
      <td className="hide">
        <span className="copy-container">
          {`${transaction?.from?.slice(0, 5) || ''}...${transaction?.from?.slice(-5) || ''}`}
          <button onClick={() => handleCopyFrom(transaction?.from)}>
            {isCopiedFrom ? (
              <Check
                style={{
                  color: 'green',
                  strokeDasharray: 50,
                  strokeDashoffset: isCopiedFrom ? 0 : -50,
                }}
              />
            ) : (
              <Clippy
                style={{
                  color: 'grey',
                  strokeDasharray: 50,
                  strokeDashoffset: isCopiedFrom ? -50 : 0,
                }}
              />
            )}
          </button>
        </span>
      </td>
      <td className="hide">
        <span className="copy-container">
          {`${transaction?.hash?.slice(0, 5) || ''}...${transaction?.hash?.slice(-5) || ''}`}
          <button onClick={() => handleCopyHash(transaction?.hash)}>
            {isCopiedHash ? (
              <Check
                style={{
                  color: 'green',
                  strokeDasharray: 50,
                  strokeDashoffset: isCopiedHash ? 0 : -50,
                }}
              />
            ) : (
              <Clippy
                style={{
                  color: 'grey',
                  strokeDasharray: 50,
                  strokeDashoffset: isCopiedHash ? -50 : 0,
                }}
              />
            )}
          </button>
        </span>
      </td>
      <td>
        <span className="status">
          Completed<span className="status-indicator"></span>
        </span>
      </td>
      <td className="transaction-button">
        <a
          href={`${getMoreDetailsLink(transaction.asset)}${transaction?.hash}`}
          target="_blank"
          rel="noreferrer"
          className="table-container-buttons"
        >
          <img src={eye} alt="more" />
          <span>More</span>
        </a>
        <Button
          title="More"
          color="black"
          clickHandler={() => window.location.assign(`${getMoreDetailsLink(transaction.asset)}${transaction?.hash}`)}
          className="table-container-buttons-mobile"
        />
      </td>
    </tr>
  );
};

export default TransactionItem;
