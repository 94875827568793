import React, { useEffect, useState, lazy, Suspense, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';

import sendRequest from 'service';
import { Button } from 'components';
import eth from 'assets/images/eth.png';
import trx from 'assets/images/trx.png';
import wbtc from 'assets/images/btc.png';
import usdt from 'assets/images/usdt.png';
import question from 'assets/images/question.svg';
import right_icon from 'assets/images/right_icon.svg';
import './Send.scss';
import { addNotification } from 'store/features/notificationsSlice';

const SelectCurrencyModal = lazy(() => import('../../modals/SelectCurrencyModal'));

const CURRENCY_LIST = [
  {
    img: eth,
    name: 'Ethereum',
    ticker: 'eth',
    network: 'erc-20',
  },
  {
    img: usdt,
    name: 'Tether USD ERC-20',
    ticker: 'usdt',
    network: 'erc-20',
  },
  {
    img: usdt,
    name: 'Tether USD TRC-20',
    ticker: 'usdt',
    network: 'trc-20',
  },
  {
    img: wbtc,
    name: 'Bitcoin',
    ticker: 'btc',
    network: 'btc',
  },
  {
    img: trx,
    name: 'TRON',
    ticker: 'trx',
    network: 'trc-20',
  },
];

interface ErrorResponse {
  error: string;
  isOpen: boolean;
}

export const Send = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currency = queryParams.get('currency');

  const [isSuccessful, setIsSuccessful] = useState(false);
  const [value, setValue] = useState('');
  const [usdValue, setUsdValue] = useState(0);
  const [rates, setRates] = useState<[]>([]);
  const [address, setAddress] = useState('');
  const [token, setToken] = useState(CURRENCY_LIST[0]);
  const [isAvailable, setIsAvailable] = useState(false);
  const [balances, setBalances] = useState<[]>([]);
  const [coinBalance, setCoinBalance] = useState(0);
  const [feePrice, setFeePrice] = useState(0);
  const [currencyList, setCurrencyList] = useState(CURRENCY_LIST);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const dispatch = useDispatch();

  const findExactRate = useCallback((ratesArray: []) => {
    const coin = ratesArray.find((coin: any) => coin.symbol.toLowerCase() === token.ticker.toLowerCase());

    return coin;
  }, [token.ticker]);

  const handleSelectToken = useCallback((tkn: any) => {
    const finded = currencyList.find((el) => el.name === tkn.name && el.network === tkn.network);
    setToken(finded!);
    handleCloseModal();
  }, [currencyList]);

  useEffect(() => {
    if (currency && currencyList.length) {
      const defaultValue = currencyList.find((item) => item.ticker.toLocaleLowerCase() === currency.toLocaleLowerCase());
      handleSelectToken(defaultValue);
    }
  }, [currency, currencyList, handleSelectToken]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  const handleError = (err: AxiosError) => {
    if (err.isAxiosError && err.response) {
      const data = err.response.data as ErrorResponse;
      data.isOpen = true;
      dispatch(addNotification({ title: 'Error!', message: data.error, type: 'error' }));
    }
  };

  const findExactBalance = (balancesArray: []) => {
    const balance = balancesArray.find(
      (bal: any) => bal.ticker === token.ticker.toUpperCase() && bal.network === token.network
    );

    return balance;
  };

  const handleGetRates = (res: AxiosResponse) => {
    setRates(res.data);
    const rate: any = findExactRate(res.data);
    setUsdValue(rate?.current_price);
  };

  useEffect(() => {
    if (rates.length) {
      const rate: any = findExactRate(rates);
      setUsdValue(rate?.current_price || 0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, rates])

  const handleGetBalances = (res: AxiosResponse) => {
    setBalances(res.data);
    const balance: any = findExactBalance(res.data);
    setCoinBalance(balance?.balance);
    const currList = res.data.map((el: any) => {
      return { img: el.image, name: el.name, ticker: el.ticker, network: el.network };
    });
    setCurrencyList(currList);
  };

  const handleGetFees = (res: AxiosResponse) => {
    setFeePrice(res.data);
  };

  const sendCrypto = async () => {
    setIsAvailable(false);
    if (usdValue && Number(value) && address && Number(coinBalance) >= Number(value)) {
      sendRequest(
        'post',
        '/api/send-transaction',
        {
          addressTo: address,
          amount: value,
          walletIndex: token.ticker,
          network: token.network,
        },
        () => {
          setIsSuccessful(true);
          sendRequest('GET', '/api/balances', undefined, handleGetBalances, handleError, undefined);
        },
        handleError,
        undefined
      );
    }
  };

  const processTotalFees = () => {
    const serviceFeeValue = Number(value) / 100;
    let nativeServiceFee = 0;

    switch (token.network) {
      case 'erc-20':
        const ethRate: any = rates.find((coin: any) => coin.symbol === token.ticker.toLowerCase());
        if (token.ticker.toLowerCase() !== 'eth' && token.ticker.toLowerCase() !== 'usdt') {
          nativeServiceFee = serviceFeeValue / Number(ethRate?.current_price);
          return `${Math.max(nativeServiceFee * ethRate?.current_price, 0)} ${token.ticker} ($${Math.max(
            nativeServiceFee * ethRate?.current_price,
            0
            // 1
          ).toFixed(2)})`;
        } else if (token.ticker.toLowerCase() === 'usdt') {
          nativeServiceFee = serviceFeeValue / Number(ethRate?.current_price);
          return `${Math.max(nativeServiceFee * ethRate?.current_price, 1)} usdt ($${Math.max(
            nativeServiceFee * ethRate?.current_price,
            1
          ).toFixed(2)})`;
        } else {
          nativeServiceFee = serviceFeeValue;
        }
        return `${(feePrice * 21000 * 10e-10 + nativeServiceFee).toFixed(5)} eth ($${(
          feePrice * 21000 * 10e-10 * ethRate?.current_price +
          nativeServiceFee * ethRate?.current_price
        ).toFixed(2)})`;

      case 'trc-20':
        const trxRate: any = rates.find((coin: any) => coin.symbol === 'trx');
        if (token.ticker.toLowerCase() !== 'trx' && token.ticker.toLowerCase() !== 'usdt') {
          nativeServiceFee = serviceFeeValue / Number(trxRate?.current_price);
          return `${Math.max(nativeServiceFee * trxRate?.current_price, 0)} usdt ($${Math.max(
            nativeServiceFee * trxRate?.current_price,
            0
            // 1
          ).toFixed(2)})`;
        } else if (token.ticker.toLowerCase() === 'usdt') {
          nativeServiceFee = serviceFeeValue / Number(trxRate?.current_price);
          return `${Math.max(nativeServiceFee * trxRate?.current_price, 1)} usdt ($${Math.max(
            nativeServiceFee * trxRate?.current_price,
            1
          ).toFixed(2)})`;
        } else {
          nativeServiceFee = serviceFeeValue;
        }

        return `${(feePrice * 20000 * 10e-6 + nativeServiceFee).toFixed(5)} trx ($${(
          feePrice * 20000 * 10e-6 * trxRate?.current_price +
          nativeServiceFee * trxRate?.current_price
        ).toFixed(2)})`;

      case 'btc':
        const btcRate: any = rates.find((coin: any) => coin.symbol === 'btc');
        nativeServiceFee = serviceFeeValue;
        return `${(feePrice * 226 * 10e-8 + nativeServiceFee).toFixed(8)} btc ($${(
          feePrice * 226 * 10e-8 * btcRate?.current_price +
          nativeServiceFee * btcRate?.current_price
        ).toFixed(2)})`;

      default:
        return '';
    }
  };

  const processServiceFee = (val: string) => {
    let serviceFeeValue: any = Number(val) / 100;
    let coin = '';
    let serviceFeeInNativeCoin = serviceFeeValue;

    switch (token.network) {
      case 'erc-20':
        coin = 'eth';
        serviceFeeValue = rates.find((coin: any) => coin.symbol.toLowerCase() === token.ticker.toLowerCase());
        if (token.ticker.toLowerCase() !== 'eth' && token.ticker.toLowerCase() !== 'usdt') {
          // coin = 'usdt';
          coin = token.ticker;
          serviceFeeValue = rates.find((coin: any) => coin.symbol === token.ticker.toLowerCase());
          serviceFeeInNativeCoin = Math.max(Number(val) /* / Number(serviceFeeValue?.current_price)*/ / 100, 0);
        } else if (token.ticker.toLowerCase() === 'usdt') {
          coin = token.ticker;
          serviceFeeValue = rates.find((coin: any) => coin.symbol === token.ticker.toLowerCase());
          serviceFeeInNativeCoin = Math.max(Number(val) /* / Number(serviceFeeValue?.current_price)*/ / 100, 1);
        }
        break;
      case 'trc-20':
        coin = 'trx';
        serviceFeeValue = rates.find((coin: any) => coin.symbol === token.ticker.toLowerCase());
        if (token.ticker.toLowerCase() !== 'trx' && token.ticker.toLowerCase() !== 'usdt') {
          // coin = 'usdt';
          coin = token.ticker;
          // serviceFeeValue = rates.find((coin: any) => coin.symbol === token.ticker.toLowerCase());
          serviceFeeInNativeCoin = Math.max(Number(val) /* / Number(serviceFeeValue?.current_price)*/ / 100, 1);
        } else if (token.ticker.toLowerCase() === 'usdt') {
          coin = token.ticker;
          // serviceFeeValue = rates.find((coin: any) => coin.symbol === token.ticker.toLowerCase());
          serviceFeeInNativeCoin = Math.max(Number(val) /* / Number(serviceFeeValue?.current_price)*/ / 100, 1);
        }
        break;
      case 'btc':
        coin = 'btc';
        const btcRate: any = rates.find((coin: any) => coin.symbol === 'btc');
        serviceFeeInNativeCoin = serviceFeeValue;
        serviceFeeValue = btcRate;
        break;
      default:
        break;
    }

    return `${serviceFeeInNativeCoin.toFixed(5)} ${coin} ($${(
      Number(serviceFeeValue?.current_price) * Number(serviceFeeInNativeCoin)
    ).toFixed(2)})`;
  };

  const processMinerFee = () => {
    let serviceFeeValue: any = { current_price: 1 };

    switch (token.network) {
      case 'erc-20':
        serviceFeeValue = rates.find((coin: any) => coin.symbol.toLowerCase() === 'eth');
        if (screenWidth > 768) {
          return `${feePrice} Gwei * 21000 Gas = ${(Number(feePrice) * 21000 * 10e-10).toFixed(5)} eth ($${Number(
            feePrice * 21000 * 10e-10 * serviceFeeValue?.current_price
          ).toFixed(2)})`;
        } else {
          return `${(Number(feePrice) * 21000 * 10e-10).toFixed(5)} eth ($${Number(
            feePrice * 21000 * 10e-10 * serviceFeeValue?.current_price
          ).toFixed(2)})`;
        }
      case 'trc-20':
        serviceFeeValue = rates.find((coin: any) => coin.symbol === 'trx');
        if (screenWidth > 768) {
          return `${feePrice} Gwei * 20000 Gas = ${(Number(feePrice) * 20000 * 10e-6).toFixed(5)} trx ($${Number(
            feePrice * 20000 * 10e-6 * serviceFeeValue?.current_price
          ).toFixed(2)})`;
        } else {
          return `${(Number(feePrice) * 20000 * 10e-6).toFixed(5)} trx ($${Number(
            feePrice * 20000 * 10e-6 * serviceFeeValue?.current_price
          ).toFixed(2)})`;
        }
      case 'btc':
        const btcRate: any = rates.find((coin: any) => coin.symbol === 'btc');
        if (screenWidth > 768) {
          return `${feePrice} sat/byte * 226 bytes = ${(Number(feePrice) * 226 * 10e-8).toFixed(8)} btc ($${Number(
            feePrice * 226 * 10e-8 * btcRate?.current_price
          ).toFixed(2)})`;
        } else {
          return `${(Number(feePrice) * 226 * 10e-8).toFixed(8)} btc ($${Number(
            feePrice * 226 * 10e-8 * btcRate?.current_price
          ).toFixed(2)})`;
        }
      default:
        return '';
    }
  };

  useEffect(() => {
    sendRequest('GET', '/api/get-rates', undefined, handleGetRates, handleError, undefined);
    sendRequest('GET', '/api/wallets', undefined, handleGetBalances, handleError, undefined);
    sendRequest('post', '/api/estimate-fee', { network: token.network }, handleGetFees, handleError, undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rates.length > 0) {
      sendRequest('post', '/api/estimate-fee', { network: token.network }, handleGetFees, handleError, undefined);
      const rate: any = findExactRate(rates);
      setUsdValue(rate?.current_price);
      const balance: any = findExactBalance(balances);
      setCoinBalance(balance?.balance);
      setIsSuccessful(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token.ticker]);

  useEffect(() => {
    if (usdValue && Number(value) && address && Number(coinBalance) >= Number(value)) {
      setIsAvailable(true);
    } else {
      setIsAvailable(false);
    }
  }, [usdValue, value, address, coinBalance]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="send">
      <div className="send-container">
        {isModalOpen ? (
          <Suspense fallback={<></>}>
            <SelectCurrencyModal
              currencyList={currencyList}
              handleClose={handleCloseModal}
              handleChange={handleSelectToken}
            />
          </Suspense>
        ) : null}
        <div className="send-container-buttons">
          <Button title="Buy" color="white" clickHandler={() => navigate('/buy')} />
          <Button title="Send" color="orange" clickHandler={() => console.log('send')} />
        </div>
        <div className="send-container-balance __buy">
          <span className="send-container-balance-value">
            <input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="send-container-balance-value-input"
              placeholder="0"
            />
            <span className="send-container-balance-value-helper">({token.ticker.toUpperCase()})</span>
          </span>
          <span className="send-container-balance-helper">
            <span>{`≈ ${usdValue * Number(value)} $`}</span>
            <span id="max">
              Maximum amount you can send is {coinBalance > 0 ? coinBalance?.toFixed(5) : 0} {token.ticker}
              <img src={question} alt="info" />
            </span>
          </span>
        </div>
        <div className="send-container-currency">
          <span className="send-container-currency-title">Currency: </span>
          <div className="send-container-currency-block" onClick={handleOpenModal}>
            <div className="send-container-currency-block-element">
              <img className="send-container-currency-block-element-image" src={token.img} alt="bnb" />
              <span className="send-container-currency-block-element-name" id="currency">
                {token.name}
              </span>
              <span className="send-container-currency-block-element-helper">({token.ticker.toUpperCase()})</span>
            </div>
            <div className="send-container-currency-block-element">
              <span className="send-container-currency-block-element-helper">
                1 {token.ticker.toUpperCase()} ≈ {usdValue} USD
              </span>
              <span className="send-container-currency-block-element-select">
                <img src={right_icon} alt="right" className="send-container-currency-block-element-select__img" />
              </span>
            </div>
          </div>
        </div>
        <div className="send-container-using">
          <span className="send-container-using-title">Address: </span>
          <input
            type="text"
            className="send-container-using-input"
            placeholder="Enter the recipient's address"
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="send-container-fees">
          <div className="send-container-fees-element">
            <span className="send-container-fees-element-block">
              <img src={question} alt="question" />
              <span className="send-container-fees-element-block-title">Miner fee:</span>
            </span>
            <span className="send-container-fees-element-helper">{processMinerFee()}</span>
          </div>
          <div className="send-container-fees-element">
            <span className="send-container-fees-element-block">
              <img src={question} alt="question" />
              <span className="send-container-fees-element-block-title">Service fee:</span>
            </span>
            <span className="send-container-fees-element-helper">{processServiceFee(value)}</span>
          </div>
          <div className="send-container-fees-element">
            <span className="send-container-fees-element-block">
              <span className="send-container-fees-element-block-title">Total fees you pay:</span>
            </span>
            <span className="send-container-fees-element-helper">{processTotalFees()}</span>
          </div>
        </div>
        <Button
          title="Continue"
          color="orange"
          clickHandler={sendCrypto}
          className={`send-container-submit ${isAvailable ? '' : 'disabled'}`}
        />
        {isSuccessful && (
          <div className="send-container-info">Your request has been successfully submitted for processing</div>
        )}
      </div>
    </div>
  );
};
