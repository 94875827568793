import React, { useState } from 'react';

import { IWallet } from 'type';
import eth from 'assets/images/eth.png';
import btc from 'assets/images/btc.png';
import usdt from 'assets/images/usdt.png';
import trx from 'assets/images/trx.png';
import walletConnect from 'assets/images/walletConnect.svg';
import useAnimatedValue from 'hooks/useAnimatedValue';
import { ReactComponent as Copy } from 'assets/images/icons/copy.svg';
import { ReactComponent as Check } from 'assets/images/icons/check.svg';

import { Button } from 'components';

const imgs: any = { eth, btc, trx, usdt };

type AssetProps = {
  asset: IWallet;
  connectionStatus: boolean;
  handleClick: () => void;
};

const ExternalWallet: React.FC<AssetProps> = ({ asset, connectionStatus, handleClick }) => {
  const [isCopiedAddress, setIsCopiedAddress] = useState(false);
  const { value: usdBalance } = useAnimatedValue(0, asset.usdBalance, 2);

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(asset.address).then(() => {
      setIsCopiedAddress(true);
      setTimeout(() => setIsCopiedAddress(false), 1500);
    });
  };

  return (
    <div className="Asset flex justify-between align-center bg-gray-100">
      <div className={`Asset__wrapper w-100 ${!connectionStatus ? 'space-between' : ''}`}>
        <div className="Asset__externalAddressWrapper flex">
          {connectionStatus ? (
            <img src={imgs[asset.ticker?.toLowerCase()]} alt="icon" className="mr-1 mr-md-2" />
          ) : (
            <img src={walletConnect} alt="icon" className="mr-1 mr-md-2" />
          )}
          <div className="flex flex-column w-75">
            <p className="Asset__title mt-0">{connectionStatus ? asset.name : 'External Wallet'}</p>
            <div className="flex">
              {
                asset.address && connectionStatus ? 
                <>
                  <p className="Asset__address font-sm m-0 color-gray text-truncate">{asset.address?.slice(0, -5)}</p>
                  <p className="Asset__address-end font-sm mt-0 mb-0 color-gray">{asset.address?.slice(-5)}</p>
                </>
                :
                <p className="font-sm m-0 color-gray text-truncate">
                  Not connected
                </p>
              }
              {connectionStatus ? (
                <button disabled={isCopiedAddress} className="btn-reset ml-1" onClick={() => handleCopyAddress()}>
                  {isCopiedAddress ? <Check stroke="#5BD548" width={14} height={15} /> : <Copy />}
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <div className="Asset__values ml-4">
          <p className="fw-500 font-sm text-nowrap">{asset.ticker?.toUpperCase()}</p>
          <p className="fw-500 font-sm text-nowrap">{connectionStatus ? asset.balance?.toFixed(5) : ''}</p>
          <p className="font-sm text-nowrap color-gray">{connectionStatus ? `≈ $${usdBalance}` : ''}</p>
        </div>
        <div className="ExternalAsset__actions">
          <Button
            title={connectionStatus ? "Disconnect" : "Connect"}
            onClick={handleClick}
            color="white"
          />
        </div>
      </div>
    </div>
  );
};

export default ExternalWallet;
