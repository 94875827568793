import React, { useEffect } from 'react';

import History from './components/History';
import './Transactions.scss';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getTansactionsHistory, selectTransactions } from 'store/features/transactionsSlice';

export const Transactions: React.FC = () => {
  const { data, loading } = useAppSelector(selectTransactions);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTansactionsHistory());
  }, [dispatch]);

  return (
    <div className="transactions">
      <div className="transactions-content">
        <div className="table-container">
          <History
            history={data}
            isLoading={loading}
          />
        </div>
      </div>
    </div>
  );
};
