import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';

import { RootState } from '..';
import { ITransactionsHistoryState } from 'type';
import { addNotification } from './notificationsSlice';

export const getTansactionsHistory = createAsyncThunk<any, void, { extra: AxiosInstance }>(
  'transactions/get', async (_, { dispatch, extra, rejectWithValue }
) => {
  try {
    const res = await extra.get('/api/transactions/db');
    return res.data;
  } catch (err: any) {
    dispatch(addNotification({ title: 'Error!', type: 'error', message: err?.response?.data?.error }));
    return rejectWithValue(err?.response?.data?.error);
  }
});

const initialState: ITransactionsHistoryState = {
  loading: false,
  error: null,
  data: []
};

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTansactionsHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTansactionsHistory.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTansactionsHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
  },
});

export const selectTransactions = (state: RootState) => state.transactions;

export default transactionsSlice.reducer;
