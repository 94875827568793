import React, { useRef } from 'react';

import { ReactComponent as Close } from "assets/images/icons/close.svg";
import './Modal.scss';
import useClickOutside from 'hooks/useClickOutside';

interface ModalType {
  title: string;
  handleClose: (type: string) => void;
  type: 'name' | 'nickname' | 'email' | 'password' | 'new-token';
  children: React.ReactNode;
}

export const Modal: React.FC<ModalType> = ({ title, handleClose, type, children }) => {
  const modalRef = useRef(null);
  const handleCloseClick = () => {
    handleClose(type);
  };
  useClickOutside(modalRef, handleCloseClick);

  return (
    <div>
      <div className="overlay" />
      <div className="modal" ref={modalRef}>
        <div className="modal-header">
          <span className="modal-header__title">{title}</span>
          <button 
            className="modal-header__close btn-reset flex align-center justify-center" 
            onClick={handleCloseClick}
          >
            <Close />
          </button>
        </div>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};
