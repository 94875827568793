import React, { useEffect, useState, lazy, Suspense, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import sendRequest from 'service';
import QRCode from 'react-qr-code';

import { Button } from 'components';
import trx from 'assets/images/trx.png';
import eth from 'assets/images/eth.png';
import btc from 'assets/images/btc.png';
import usdt from 'assets/images/usdt.png';
import right_arrow from 'assets/images/right-arrow.svg';
import { ReactComponent as Clippy } from 'assets/images/icons/clippy.svg';
import { ReactComponent as Check } from 'assets/images/icons/check.svg';
import { addNotification } from 'store/features/notificationsSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getWallets, selectWallets } from 'store/features/walletsSlice';
import PaymentMethods from './components/PaymentMethods';

const SelectCurrencyModal = lazy(() => import('../../modals/SelectCurrencyModal'));

const CURRENCY_LIST = [
  {
    img: eth,
    name: 'Ethereum',
    ticker: 'eth',
    network: 'erc-20',
  },
  {
    img: usdt,
    name: 'Tether USD ERC-20',
    ticker: 'usdt',
    network: 'erc-20',
  },
  {
    img: usdt,
    name: 'Tether USD TRC-20',
    ticker: 'usdt',
    network: 'trc-20',
  },
  {
    img: btc,
    name: 'Bitcoin',
    ticker: 'btc',
    network: 'btc',
  },
  {
    img: trx,
    name: 'TRON',
    ticker: 'trx',
    network: 'trc-20',
  },
];

interface ErrorResponse {
  error: string;
  isOpen: boolean;
}

export const Buy: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currency = queryParams.get('currency');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState(CURRENCY_LIST[0]);
  const [address, setAddress] = useState('');
  const [rates, setRates] = useState<[]>([]);
  const [usdValue, setUsdValue] = useState(0);
  const [isCopiedAddress, setIsCopiedAddress] = useState(false);
  const [currencyList, setCurrencyList] = useState(CURRENCY_LIST);
  
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: { balances }, loading } = useAppSelector(selectWallets);

  const handleCopyAddress = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setIsCopiedAddress(true);
      setTimeout(() => setIsCopiedAddress(false), 1000);
    });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSelectToken = useCallback((tkn: any) => {
    const finded = currencyList.find(
      (el) => el.ticker.toLowerCase() === tkn.ticker.toLowerCase() && el.network === tkn.network
    );
    setToken(finded!);
    let addr: any;
    if (finded?.ticker === 'usdt') {
      addr = balances.find((el: any) => el.network === tkn.network);
    } else {
      addr = balances.find((el: any) => el.name === tkn.name && el.network === tkn.network);
    }
    setAddress(addr?.address);
    handleCloseModal();
  }, [balances, currencyList]);

  useEffect(() => {
    if (currency && currencyList.length) {
      const defaultValue = currencyList.find((item) => item?.ticker.toLocaleLowerCase() === currency.toLocaleLowerCase());
      handleSelectToken(defaultValue);
    }
  }, [currency, currencyList, handleSelectToken]);

  const findExactRate = (ratesArray: []) => {
    return ratesArray.find((coin: any) => coin.symbol.toLowerCase() === token.ticker.toLowerCase());
  };

  const handleGetRates = (res: AxiosResponse) => {
    setRates(res.data);
    const rate: any = findExactRate(res.data);
    setUsdValue(rate.current_price);
  };

  const handleError = (err: AxiosError) => {
    if (err.isAxiosError && err.response) {
      const data = err.response.data as ErrorResponse;
      data.isOpen = true;
      dispatch(addNotification({ title: 'Error!', message: data.error, type: 'error' }));
    }
  };

  useEffect(() => {
    if (!loading && balances.length) {
      const defaultTokenAddress = balances.find((el: any) => el.ticker === 'ETH');
      setAddress(defaultTokenAddress?.address);
      const currList = balances.map((el: any) => ({ img: el.image, name: el.name, ticker: el.ticker, network: el.network }));
      setCurrencyList(currList);
    }
  }, [balances, loading])

  useEffect(() => {
    dispatch(getWallets())
    sendRequest('GET', '/api/get-rates', undefined, handleGetRates, handleError, undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rates.length) {
      const rate: any = findExactRate(rates);
      setUsdValue(rate?.current_price);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token.ticker, findExactRate]);

  return (
    <div className="send">
      <div className="send-container">
        {isModalOpen ? (
          <Suspense fallback={<></>}>
            <SelectCurrencyModal
              currencyList={currencyList}
              handleClose={handleCloseModal}
              handleChange={handleSelectToken}
            />
          </Suspense>
        ) : null}
        <div className="send-container-buttons">
          <Button title="Buy" color="orange" clickHandler={() => console.log('buy')} />
          <Button title="Send" color="white" clickHandler={() => navigate('/send')} />
        </div>
        <PaymentMethods token={token} />
        <div className={`send-container-currency ${address ? 'mb-3' : 'mb-0'}`}>
          <span className="send-container-currency-title">Currency: </span>
          <div className="send-container-currency-block" onClick={handleOpenModal}>
            <div className="send-container-currency-block-element">
              <img className="send-container-currency-block-element-image" src={token.img} alt="bnb" />
              <span className="send-container-currency-block-element-name" id="currency">
                {token.name}
              </span>
              <span className="send-container-currency-block-element-helper">({token.ticker.toUpperCase()})</span>
            </div>
            <div className="send-container-currency-block-element">
              <span className="send-container-currency-block-element-helper">
                1 {token.ticker.toUpperCase()} ≈ {usdValue} USD
              </span>
              <span className="send-container-currency-block-element-select">
                <img
                  src={right_arrow}
                  className="send-container-currency-block-element-select__img"
                  alt="right arrow"
                />
              </span>
            </div>
          </div>
        </div>
        {address && (
          <div className="send-container-qr">
            <div className="send-container-qr-currency">
              <span className="send-container-qr-currency-title">Qr-code for:</span>
              <span className="send-container-qr-currency-coin">
                <img src={token.img} alt="coin" />
                <span>{token.ticker.toUpperCase()}</span>
              </span>
            </div>
            <QRCode value={address} className="send-container-qr-code" />
            <div className="send-container-qr-helper">
              <span>{address}</span>
              {/* <img src={copy} alt="copy" /> */}
              <button onClick={() => handleCopyAddress(address)}>
                {isCopiedAddress ? (
                  <Check
                    style={{
                      color: 'green',
                      strokeDasharray: 50,
                      strokeDashoffset: isCopiedAddress ? 0 : -50,
                    }}
                  />
                ) : (
                  <Clippy
                    style={{
                      color: 'grey',
                      strokeDasharray: 50,
                      strokeDashoffset: isCopiedAddress ? -50 : 0,
                    }}
                  />
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
