import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components';
import { toggleNavigation, selectNavigation } from 'store/features/uiSlice';
import { getUser, selectUser } from 'store/features/userSlice';
import menu from 'assets/images/menu.png';
import notif from 'assets/images/bell.png';
import power from 'assets/images/power.svg';
import close from 'assets/images/close.png';
import notif_active from 'assets/images/bell.png';
import logo_small from 'assets/images/logo/mini-logo.svg';
import { ReactComponent as DefaultAvatar } from 'assets/images/icons/defaultAvatar.svg';
import { useAppSelector, useAppDispatch } from 'hooks';
import './Header.scss';

type HeaderProps = {
  title: string;
};

export const Header = ({ title }: HeaderProps) => {
  const [isUserModalVisible, setUserModalVisible] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isActive = useAppSelector(selectNavigation);
  const { data: profile } = useAppSelector(selectUser);
  // const error = useAppSelector((state) => state.user.error);
  const handleMouseEnter = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
    setUserModalVisible(true);
  };

  const handleMouseLeave = () => {
    setHoverTimeout(
      setTimeout(() => {
        setUserModalVisible(false);
      }, 500) // 500ms delay, adjust as necessary
    );
  };

  const handleLogout = () => {
    dispatch({ type: 'auth/logout'})
    localStorage.removeItem('Access-Token');
    localStorage.removeItem('remember-me');

    navigate('/login');
  };

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const handleToggleNavigation = () => {
    dispatch(toggleNavigation());
  };

  return (
    <div className="header">
      <span className="logo-container-mob">
        <img
          className="logo-container-mob-img"
          src={logo_small}
          alt="coinbooster"
          onClick={() => navigate('/dashboard')}
        />
      </span>
      <div className="header-item">
        <div className="title">
          <h3>{title}</h3>
        </div>
      </div>
      <div className="header-block">
        <div className="buttons-block">
          <Button title="Buy" color="orange" clickHandler={() => navigate('/buy')} />
          <Button title="Send" color="white" clickHandler={() => navigate('/send')} />
        </div>
        <div className="notification">
          <img src={notif} alt="notification" />
        </div>
        <div
          className="user"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          // onMouseEnter={() => setUserModalVisible(true)}
          // onMouseLeave={() => setUserModalVisible(false)}
        >
          {/* onClick={() => navigate('/profile')}> */}
          {profile?.avatar ?
            <img
              src={profile?.avatar}
              alt={`${profile?.name} avatar`}
            />
            : <DefaultAvatar />}
          {isUserModalVisible && (
            <div
              className="user-modal"
              onMouseEnter={() => {
                if (hoverTimeout) {
                  clearTimeout(hoverTimeout);
                  setHoverTimeout(null);
                }
              }}
              onMouseLeave={handleMouseLeave}
            >
              <span className="user-modal-element" onClick={() => navigate('/profile')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path
                    d="M11 11C12.2764 11 13.5004 10.493 14.403 9.59045C15.3055 8.68793 15.8125 7.46385 15.8125 6.1875C15.8125 4.91115 15.3055 3.68707 14.403 2.78455C13.5004 1.88203 12.2764 1.375 11 1.375C9.72365 1.375 8.49957 1.88203 7.59705 2.78455C6.69453 3.68707 6.1875 4.91115 6.1875 6.1875C6.1875 7.46385 6.69453 8.68793 7.59705 9.59045C8.49957 10.493 9.72365 11 11 11ZM14.2083 6.1875C14.2083 7.0384 13.8703 7.85445 13.2686 8.45613C12.667 9.05781 11.8509 9.39583 11 9.39583C10.1491 9.39583 9.33304 9.05781 8.73137 8.45613C8.12969 7.85445 7.79167 7.0384 7.79167 6.1875C7.79167 5.3366 8.12969 4.52054 8.73137 3.91887C9.33304 3.31719 10.1491 2.97917 11 2.97917C11.8509 2.97917 12.667 3.31719 13.2686 3.91887C13.8703 4.52054 14.2083 5.3366 14.2083 6.1875ZM20.625 19.0208C20.625 20.625 19.0208 20.625 19.0208 20.625H2.97917C2.97917 20.625 1.375 20.625 1.375 19.0208C1.375 17.4167 2.97917 12.6042 11 12.6042C19.0208 12.6042 20.625 17.4167 20.625 19.0208ZM19.0208 19.0144C19.0192 18.6198 18.7738 17.4327 17.6862 16.3451C16.6403 15.2992 14.6719 14.2083 11 14.2083C7.32646 14.2083 5.35975 15.2992 4.31383 16.3451C3.22621 17.4327 2.98237 18.6198 2.97917 19.0144H19.0208Z"
                    fill="black"
                  />
                </svg>
                <p>Profile</p>
              </span>
              <span
                className="user-modal-element"
                onClick={handleLogout}
              >
                <img src={power} alt="logout" />
                <p>Log out</p>
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="header-block-mob">
        {isActive ? (
          <div className="notification">
            <img src={notif_active} alt="notification" />
          </div>
        ) : (
          <Button
            title="Buy crypto"
            color="orange"
            clickHandler={() => navigate('/buy')}
            className="header-block-mob-buy"
          />
        )}
        <div className="menu" onClick={handleToggleNavigation}>
          <img src={isActive ? close : menu} alt="menu" />
        </div>
      </div>
    </div>
  );
};
