import axios from 'axios';

const apiReq  = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

apiReq.interceptors.request.use((config) => {
  const token = localStorage.getItem('Access-Token');
  if (token) config.headers['Authorization'] = `Bearer ${token}`;

  return config;
});

export default apiReq;
