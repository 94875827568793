import React, { useState, useEffect, useRef, Suspense, lazy } from 'react';

import { changePassword, deleteAvatar, editUser, getUser, selectUser, uploadAvatar } from 'store/features/userSlice';
import { Button } from 'components';
import pen from 'assets/images/pen.svg';
import trash from 'assets/images/trash.svg';
import './Profile.scss';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ReactComponent as DefaultAvatar } from 'assets/images/ion_person_preview.svg';

const PasswordModal = lazy(() => import('../../modals/PasswordModal'));
const NameModal = lazy(() => import('../../modals/NameModal'));
const NicknameModal = lazy(() => import('../../modals/NicknameModal'));
const EmailModal = lazy(() => import('../../modals/EmailModal'));

type ProfileType = {
  name: string;
  email: string;
  nickname: string;
  avatar: string | null;
  password: string | null;
};

export const Profile = () => {
  const [profile, setProfile] = useState<ProfileType>({
    name: '',
    email: '',
    nickname: '',
    avatar: null,
    password: '',
  });
  const [values, setValues] = useState({
    name: true,
    nickname: true,
    email: true,
    password: true,
  });
  const [isModalOpen, setIsModalOpen] = useState({ name: false, nickname: false, email: false, password: false });
  const [avatarImage, setAvatarImage] = useState<File | null>(null);
  const dispatch = useAppDispatch();
  const { data, loading } = useAppSelector(selectUser);
  const avatarRef = useRef<HTMLInputElement>(null);

  const changeAvatar = () => {
    avatarRef.current?.click();
  };

  const handleOpenModal = (modalType: string) => {
    setIsModalOpen({ ...isModalOpen, [modalType]: true });
  };

  const handleCloseModal = (modalType: string) => {
    setIsModalOpen({ ...isModalOpen, [modalType]: false });
    setValues({ ...values, [modalType]: true });
  };

  useEffect(() => {
    if (!data.name && !loading) dispatch(getUser());
  }, [dispatch, data.name, loading]);

  useEffect(() => {
    if (avatarImage) {
      const formdata = new FormData();
      formdata.append('avatar', avatarImage);
      dispatch(uploadAvatar(formdata));
    }
  }, [avatarImage, dispatch]);

  const handleChange = (e: any, field: 'name' | 'nickname' | 'email' | 'password') => {
    setProfile({ ...profile, [field]: e.target.value });
  };

  const handleChangePassword = (value: string) => {
    setProfile({ ...profile, password: value });
  };

  const handleUpdateField = async (field: 'name' | 'nickname' | 'email' | 'password') => {
    let newProfileData: Object = {};

    if ('name' || 'nickname' || 'email' || 'password') {
      handleOpenModal(field);
      if (!values[field]) {
        newProfileData = { [field]: profile[field] };
      }
    }

    if (Object.keys(newProfileData).length) {
      dispatch(editUser(newProfileData));
    }
  };

  const handleAvatarDelete = () => {
    dispatch(deleteAvatar());
  };

  const handleUpdatePassword = (oldPassword: string, newPassword: string) => {
    dispatch(changePassword({ currentPassword: oldPassword, newPassword: newPassword }));
  };

  return (
    <div className="profile">
      <div className="profile-container">
        {isModalOpen.name ? (
          <Suspense fallback={<></>}>
            <NameModal
              name={data.name}
              handleClose={handleCloseModal}
              handleChange={handleChange}
              clickHandler={handleUpdateField}
            />
          </Suspense>
        ) : null}
        {isModalOpen.nickname ? (
          <Suspense fallback={<></>}>
            <NicknameModal
              name={data.nickname}
              handleClose={handleCloseModal}
              handleChange={handleChange}
              clickHandler={handleUpdateField}
            />
          </Suspense>
        ) : null}
        {isModalOpen.email ? (
          <Suspense fallback={<></>}>
            <EmailModal
              name={data.email}
              handleClose={handleCloseModal}
              handleChange={handleChange}
              clickHandler={handleUpdateField}
            />
          </Suspense>
        ) : null}
        {isModalOpen.password ? (
          <Suspense fallback={<></>}>
            <PasswordModal
              // name={profile.password}
              handleClose={handleCloseModal}
              handleChange={handleChangePassword}
              clickHandler={handleUpdatePassword}
            />
          </Suspense>
        ) : null}
        <div className="profile-container-main">
          <span className="profile-container-main-title">Main information</span>
          <div className="profile-container-main-block">
            <div className="profile-container-main-block-photo">
              {data.avatar ? <img src={data.avatar} alt="avatar" /> : <DefaultAvatar width={100} height={100} />}
              <span className="profile-container-main-block-photo-upload">
                <span className="profile-container-main-block-photo-upload-title">Change picture</span>
                <span className="profile-container-main-block-photo-upload-buttons">
                  <Button title="Upload photo" color="white" clickHandler={changeAvatar} />
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={avatarRef}
                    onChange={(e) => setAvatarImage(e.target.files![0])}
                  />
                  {data.avatar ? (
                    <img className="remove-avatar" src={trash} alt="remove" onClick={handleAvatarDelete} />
                  ) : null}
                </span>
                <span className="profile-container-main-block-photo-upload-helper">Max file size is 20Mb.</span>
              </span>
            </div>
            <div className="profile-container-main-block-info">
              <div className="profile-container-main-block-info-element">
                <div>
                  <div className="profile-container-main-block-info-element-title">Name</div>
                  <div className="profile-container-main-block-info-element-value">{data.name}</div>
                </div>
                <span
                  className="profile-container-main-block-info-element-button"
                  onClick={() => {
                    setValues({ ...values, name: false });
                    handleUpdateField('name');
                  }}
                >
                  <img src={pen} alt="pen" />
                  Change
                </span>
              </div>
              <div className="profile-container-main-block-info-element">
                <div>
                  <div className="profile-container-main-block-info-element-title">NickName</div>
                  <div className="profile-container-main-block-info-element-value">{data.nickname}</div>
                </div>
                <span
                  className="profile-container-main-block-info-element-button"
                  onClick={() => {
                    setValues({ ...values, nickname: false });
                    handleUpdateField('nickname');
                  }}
                >
                  <img src={pen} alt="pen" />
                  Change
                </span>
              </div>
              <div className="profile-container-main-block-info-element">
                <div>
                  <div className="profile-container-main-block-info-element-title">E-mail address</div>
                  <div className="profile-container-main-block-info-element-value">{data.email}</div>
                </div>
                <span
                  className="profile-container-main-block-info-element-button"
                  onClick={() => {
                    setValues({ ...values, email: false });
                    handleUpdateField('email');
                  }}
                >
                  <img src={pen} alt="pen" />
                  Change
                </span>
              </div>
            </div>
          </div>
        </div>
        {!data.social_account ? (
          <div className="profile-container-security">
            <span className="profile-container-security-title">Security</span>
            <div className="profile-container-security-block">
              <div className="profile-container-security-block-element">
                <span className="profile-container-security-block-element-title">Password</span>
                <span className="profile-container-security-block-element-value">****</span>
                <span
                  className="profile-container-security-block-element-button"
                  onClick={() => {
                    setValues({ ...values, password: false });
                    handleUpdateField('password');
                  }}
                >
                  <img src={pen} alt="pen" />
                  Change
                </span>
              </div>
              {/* <div className="profile-container-security-block-element">
                              <span className="profile-container-security-block-element-title">Phone numbers</span>
                              <span className="profile-container-security-block-element-value">+*** *****18</span>
                              <span className="profile-container-security-block-element-button">
                                  <img src={pen} alt="pen" />
                                  Manage
                              </span>
                          </div>
                          <div className="profile-container-security-block-element">
                              <span className="profile-container-security-block-element-title">2-step verification</span>
                              <span className="profile-container-security-block-element-value">Authentificatior</span>
                              <span className="profile-container-security-block-element-button">
                                  <img src={pen} alt="pen" />
                                  Manage
                              </span>
                          </div> */}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
