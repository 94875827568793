import React from 'react';

import Notification from './Notification'; 
import { INotification } from 'type';
import { selectNotifications } from 'store/features/notificationsSlice';
import { useAppSelector } from 'hooks';
import './Notifications.scss';

const Notifications = () => {
  const notifications = useAppSelector(selectNotifications);

  return (
    <div className="Notifications fixed">
      {
        notifications.map((item: INotification) => 
          <Notification 
            key={item.id}
            notification={item} 
          />
        )
      }
    </div>
  );
};

export default Notifications;
