import React, { useEffect } from "react";

import { INotification } from "type";
import { ReactComponent as Info } from "assets/images/icons/info-img.svg";
import { ReactComponent as Warn } from "assets/images/icons/warn.svg";
import { ReactComponent as Error } from "assets/images/icons/error.svg";
import { ReactComponent as Success } from "assets/images/icons/success.svg";
import { ReactComponent as Close } from "assets/images/icons/close.svg";
import { useAppDispatch } from "hooks";
import { removeNotification } from "store/features/notificationsSlice";

type NotificationProps = {
  notification: INotification  
};

const Notification: React.FC<NotificationProps> = ({ notification }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(removeNotification(notification.id));
    }, 3000)
  }, [dispatch, notification.id])

  const getIcon = () => {
    switch (notification.type) {
      case 'success':
        return <Success />;

      case 'warning':
        return <Warn />;

      case 'error':
        return <Error />;

      case 'info':
        return <Info />;
    }
  };

  return (
    <div className={`Notification ${notification.type} flex justify-between align-center mb-2`}>
      <div className="flex">
        <span>
          {getIcon()}
        </span>
        <div className="flex flex-column ml-2">
          <p className="fw-500 mt-0 mb-1">
            {notification.title}
          </p>
          <p className="color-gray-500 fw-500 font-sm m-0">
            {notification.message}
          </p>
        </div>
      </div>
      <button 
        className="Notification__closeBtn p-1 btn-reset"
        onClick={() => dispatch(removeNotification(notification.id))}
      >
        <Close />
      </button>
    </div>
  );
};

export default Notification;
