import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { AxiosError, AxiosInstance } from 'axios';

import { RootState } from '..';
import type { IWallet, IWalletsState } from 'type';

export const getWallets = createAsyncThunk<IWallet[], void, { extra: AxiosInstance }>(
  'wallets/get',
  async (_, { getState, extra, rejectWithValue }) => {
    try {
      const res = await extra.get('/api/wallets');
      return res.data;
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.response?.statusText);
    }
  }
);

export const hideCustomToken = createAsyncThunk<IWallet, { ticker: string }, { extra: AxiosInstance }>(
  'wallets/hideCustomToken',
  async ({ ticker }, { getState, extra, rejectWithValue }) => {
    try {
      const res = await extra.post('/api/hide-custom-token', { ticker });
      return res.data;
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.response?.statusText);
    }
  }
);

const initialState: IWalletsState = {
  loading: false,
  error: null,
  data: {
    balances: [],
    totalBalance: 0,
  },
};

export const walletsSlice = createSlice({
  name: 'wallets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWallets.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWallets.fulfilled, (state, action) => {
        state.loading = false;
        state.data.balances = action.payload;
        state.data.totalBalance = action.payload.reduce((accumulator, asset) => {
          return accumulator + asset.usdBalance;
        }, 0);
      });
  },
});

export const selectWallets = (state: RootState) => state.wallets;
export const selectBalance = (state: RootState) => state.wallets.data.totalBalance;
export const selectWalletAddressByName = (ticker: string | null) =>
  createSelector(
    (state: RootState) => state.wallets.data.balances,
    (wallets: IWallet[]) => {
      const foundWallet = wallets.find((wallet) => wallet.ticker.toLocaleLowerCase() === ticker);
      return foundWallet ? foundWallet.address : null;
    }
  );

export default walletsSlice.reducer;
