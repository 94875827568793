import React from 'react';
import { Outlet } from 'react-router-dom';

import logo from 'assets/images/logo/logo.svg';
import slogo from 'assets/images/logo/mini-logo.svg';
import lines from 'assets/images/logo_lines.png';
import './AuthLayout.scss';
import { Link } from 'react-router-dom';

const AuthLayout = () => {
  return (
    <div className="signup">
      <div className="signup-container">
        <div className="signup-row">
          <div className="signup-logo-row">
            <Link to="/login">
              <img src={logo} alt="Coinbooster" className="coinstorm-logo" />
              <img src={slogo} alt="Coinbooster" className="coinstorm-logo-sm" />
            </Link>
          </div>
          <div className="signup-container-form-row">
            <Outlet />
          </div>
        </div>
      </div>
      <div className="signup-filler">
        <img src={lines} alt="" className="signup-filler-lines" />
      </div>
    </div>
  );
}

export default AuthLayout;
