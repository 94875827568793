import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '..';
import type { IUiState } from 'type';

const initialState: IUiState = {
  isNavOpen: false,
  isModalOpen: false,
};

export const uiSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    toggleNavigation: (state) => {
      state.isNavOpen = !state.isNavOpen;
    },
    toggleModal: (state) => {
      state.isModalOpen = !state.isModalOpen;
    }
  },
});

export const { toggleNavigation } = uiSlice.actions;

export const selectNavigation = (state: RootState) => state.ui.isNavOpen;
export const selectModal = (state: RootState) => state.ui.isModalOpen;

export default uiSlice.reducer;
