import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Button } from 'components';

export const EmailVerification = () => {
  const [emailCode, setEmailCode] = useState('');
  const [error, setErrorText] = useState('');

  const navigate = useNavigate();

  const handleEmailCodeChange = (e: any) => {
    setErrorText('');
    setEmailCode(e.target.value);
  };

  const handleSubmit = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('c');
    const emailCodeAsNumber = Number(emailCode);
    if (!emailCodeAsNumber) {
      setErrorText('Wrong verification code');
    } else {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/api/profile/verify/${code}`, {
          code: emailCodeAsNumber,
        })
        .then((res) => {
          localStorage.setItem('Access-Token', res.data.access_token);
          navigate('/dashboard');
        })
        .catch((err) => {
          setErrorText(err.response.data.error);
        });
    }
  };

  return (
    <form className="signup-container-form">
      <span className="signup-container-form-title">Email verification</span>
      <span className="signup-container-form-helper">
        Please enter the 6-digit verification code that was sent to {localStorage.getItem('email')}. The code is
        valid for 30 minutes.
      </span>
      <span className="signup-container-form-subtitle">Verification code</span>
      <input
        className="signup-container-form-input mb-3"
        type="text"
        placeholder="Enter code"
        required
        value={emailCode}
        onChange={handleEmailCodeChange}
      />
      {error ? <span className="form-error">{error}</span> : null}
      <Button
        title="Submit"
        type="button"
        color="orange"
        clickHandler={handleSubmit}
        className="signup-container-form-submit"
      />
    </form>
  );
};
