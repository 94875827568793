import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useAppDispatch } from 'hooks';

import { Button } from 'components';
import { ReactComponent as Info } from 'assets/images/icons/info.svg';
import apiReq from 'store/api';
import SocialMediaSignIn from 'components/SocialMediaSignIn';
import { getBalanceHistory } from 'store/features/balanceHistorySlice';
import { getWallets } from 'store/features/walletsSlice'

type FormData = {
  email: string;
  password: string;
};

export const Login = () => {
  const navigate = useNavigate();
  const [error, setErrorMessage] = useState('');
  const [checked, setChecked] = useState(localStorage.getItem('remember-me') === 'true');

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<FormData>();
  const dispatch = useAppDispatch();

  const handleSuccess = async (res: AxiosResponse) => {
    localStorage.setItem('Access-Token', res.data.access_token);
    await dispatch(getWallets());
    await dispatch(getBalanceHistory());
    navigate(
      '/dashboard',
      { state: { isNavFromLogin: true } }
    );
  };

  const handleRememberChange = (e: any) => setChecked(!checked);

  useEffect(() => {
    if (checked) {
      localStorage.setItem('remember-me', 'true');
    } else {
      localStorage.removeItem('remember-me');
    }
  }, [checked]);

  useEffect(() => {
    if (!localStorage.getItem('remember-me') || !localStorage.getItem('Access-Token')) {
      localStorage.removeItem('Access-Token');
      localStorage.removeItem('remember-me');
    } else {
      navigate('/dashboard');
    }
  }, [navigate]);

  const onSubmit: SubmitHandler<FormData> = async(data) => {
    try {
      const res = await apiReq.post('/api/login', data);
      await handleSuccess(res);
    } catch (err: any) {
      setErrorMessage(err.response.data['Error']);
    }
  };

  return (
    <form className="signup-container-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="signup-container-form-title">Welcome back!</div>
      <div className="signup-container-form-register __top">
        If you don't have an account - {' '}
        <Link to="/register">
          Register now
        </Link>
      </div>
      <SocialMediaSignIn onError={setErrorMessage} />
      <div className="signup-container-form-or">
        <hr color="#999" />
        <span>or</span>
        <hr color="#999" />
      </div>
      <div className="input-wrapper ">
        <label className="signup-container-form-subtitle">
          E-mail
        </label>
        <input
          {...register('email', {
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i,
              message: "Invalid email address"
            },
            required: 'Required',
          })}
          className={`signup-container-form-input${errors.email ? ' border-1-orange' : ''}`}
          placeholder="email@coinbooster.com"
        />
        <span className={`color-orange font-sm ${errors.email ? 'block' : 'none'}`}>
          <span className="mr-1">
            <Info />
          </span>
          {errors.email?.message}
        </span>
      </div>
      <div className="input-wrapper ">
        <label className="signup-container-form-subtitle">
          Password
        </label>
        <input
          {...register('password', { required: 'Required' })}
          className={`signup-container-form-input${errors.password ? ' border-1-orange' : ''}`}
          type="password"
          placeholder="Password"
        />
        <span className={`color-orange font-sm ${errors.password ? 'block' : 'none'}`}>
          <span className="mr-1">
            <Info />
          </span>
          {errors.password?.message}
        </span>
      </div>
      <div className="signup-container-form-remember">
        <span className="signup-container-form-remember-check">
          <input
            name="remember"
            id="remember"
            type="checkbox"
            onChange={handleRememberChange}
            checked={checked}
          />
          <label htmlFor="remember">Remember me</label>
        </span>
        <span className="signup-container-form-remember-forgot">
          <Link to="/reset-password">
            Forgot password?
          </Link>
        </span>
      </div>
      {error ? <span className="form-error">{error}</span> : null}
      <Button
        title="Log in"
        color="orange"
        className="signup-container-form-submit"
        type="submit"
        disabled={isSubmitting}
      />
      <span className="signup-container-form-register">
        If you don't have an account -{' '}
        <Link to="/register">
          Register now
        </Link>
      </span>
    </form>
  );
};
