import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { Link } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from 'components';
import sendRequest from 'service';
import { ReactComponent as Info } from 'assets/images/icons/info.svg';

type FormData = {
    email: string;
};

export const ResetPassword = () => {
  const [message, setMessage] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<FormData>();

  const handleSuccess = (res: AxiosResponse) => {
    setMessage(res.data.message);
  };

  const handleError = (err: any) => {
    setMessage(err.response.data['status']);
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    return sendRequest('post', '/api/profile/reset-password', data, handleSuccess, handleError, undefined);
  };

  return (
    <form className="signup-container-form" onSubmit={handleSubmit(onSubmit)}>
      <Link
        to="/login"
        className="signup-container-form-back"
      >
        <span>Back</span>
      </Link>
      <span className="signup-container-form-title">Reset your password</span>
      <span className="signup-container-form-info">
        <span>
          <Info />
        </span>
        Withdrawals and payment services will be disabled for 24 hours after you make a change to protect your
        account.
      </span>
      <div className="input-wrapper ">
        <label className="signup-container-form-subtitle">
          E-mail
        </label>
        <input
          {...register('email', { required: 'Required' })}
          type="email"
          placeholder="email@coinbooster.com"
          className={`signup-container-form-input${errors.email ? ' border-1-orange' : ''}`}
        />
        <span className={`color-orange font-sm ${errors.email ? 'block' : 'none'}`}>
          <span className="mr-1">
            <Info />
          </span>
          {errors.email?.message}
        </span>
      </div>
      {
        message ?
        <span className="form-message form-message-success">
          {message}
        </span> : null
      }
      <Button
        title="Reset"
        color="orange"
        clickHandler={() => console.log('submit')}
        className="signup-container-form-submit"
        type="submit"
        disabled={isSubmitting}
      />
      <span className="signup-container-form-helper">
        If you remember password -{' '}
        <Link to="/login">
          Log in
        </Link>
      </span>
      <span className="signup-container-form-helper">
        If you don't have an account -{' '}
        <Link to="/register">
          Register now
        </Link>
      </span>
    </form>
  );
};
