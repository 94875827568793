type Payment = {
  name: string,
  desc: string,
  link: string
};

export const PAYMENT_METHODS: Payment[] = [
  {
    name: 'itez',
    desc: 'buy Bitcoin instantly with your credit card, Visa and Mastercard are accepted. Bitcoin Core (BTC) are supported. Get started today!',
    link: 'https://itez.com/'
  },
  {
    name: 'transak',
    desc: 'Transak facilitates easy integration for users to purchase 170+ cryptocurrencies via credit/debit cards or bank transfers in 155+ countries.',
    link: 'https://transak.com/'
  },
  {
    name: 'moonPay',
    desc: 'MoonPay: Swiftly buy and sell cryptocurrencies using credit cards, bank transfers, or Apple Pay. Start your crypto journey today.',
    link: 'https://www.moonpay.com/'
  },
  {
    name: 'ramp',
    desc: 'Allow users to buy and sell crypto without leaving your app or wallet. Buy and sell crypto directly on our website.',
    link: 'https://ramp.network/buy'
  }
]