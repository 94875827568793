import React, { lazy, Suspense, useState, useEffect } from 'react';

import { IWallet } from 'type';
import { Button } from 'components';
import Asset from './Asset';
import ExternalWallet from './ExternalWallet';

import {
  connectExternalWallet,
  getExternalWallet,
  selectExternalWallets,
  deleteExternalWallet,
} from 'store/features/walletConnectSlice';
import { useAppDispatch, useAppSelector } from 'hooks';

import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount, useDisconnect, useNetwork } from 'wagmi';

const TokenModal = lazy(() => import('modals/TokenModal'));

type AssetsProps = {
  assets: IWallet[];
};

const Assets: React.FC<AssetsProps> = ({ assets = [] }) => {
  const [isCustomTokenOpen, setIsCutomTokenOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { data: externalWallets } = useAppSelector(selectExternalWallets);

  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();

  const [connectionStatus, setConnectionStatus] = useState(isConnected);

  const handleOpenWeb3Modal = () => {
    if (!connectionStatus) {
      open();
    } else {
      disconnect();
      dispatch(deleteExternalWallet());
    }
  };

  useEffect(() => {
    dispatch(getExternalWallet());
  }, [dispatch]);

  useEffect(() => {
    if (!connectionStatus && isConnected) {
      dispatch(connectExternalWallet({ network: chain?.name as unknown as string, address: address as string }));
    }
    setConnectionStatus(isConnected);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isConnected]);

  return (
    <div className="Assets bg-white mb-2">
      <div className="flex justify-between align-center">
        <p className="font-lg fw-500 m-0">Your assets</p>
        <div className="none flex-md">
          <Button title="Add currency" color="orange" className="mr-2" />
          <Button title="Add custom token" color="white" onClick={() => setIsCutomTokenOpen(true)} />
        </div>
      </div>
      <hr className="mt-3 mb-3" />
      <ExternalWallet
        asset={externalWallets}
        connectionStatus={connectionStatus}
        handleClick={handleOpenWeb3Modal}
      />
      {assets.map((item) => (
        <Asset asset={item} key={item.id} />
      ))}
      <div className="flex flex-column none-md">
        <Button title="Add currency" color="orange" className="mb-1" />
        <Button title="Add custom token" color="white" onClick={() => setIsCutomTokenOpen(true)} />
      </div>
      {isCustomTokenOpen ? (
        <Suspense fallback={<></>}>
          <TokenModal handleClose={() => setIsCutomTokenOpen(false)} />
        </Suspense>
      ) : null}
    </div>
  );
};

export default Assets;
