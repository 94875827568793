import React from 'react';

import './Table.scss';

type TableProps = {
  title: string | React.ReactNode;
  buttons: React.ReactNode;
  classname?: string;
  children: React.ReactNode;
};

export const Table: React.FC<TableProps> = ({
  title,
  buttons,
  classname,
  children
}) => {
  return (
    <div className={`table ${classname}`}>
      <div className="table-header">
        <span className="table-header-title">{title}</span>
        <>{buttons}</>
      </div>
      <div className="table-content">{children}</div>
    </div>
  );
};
