import React from 'react';

import { Navigation, Header } from '..';
import { LayoutProps } from "type"
import { BottomStickyMenu } from 'components';

export const Layout: React.FC<LayoutProps> = ({ title, children }) => {
  return (
    <>
      <Navigation />
      <Header title={title} />
      {children}
      <BottomStickyMenu />
    </>
  );
};
