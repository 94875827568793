import React from 'react';
import { Link } from 'react-router-dom';

import Card from "../Card";
import "./CardEmptyBalance.scss"
import Button from 'components/Button';

export default function CardEmptyBalance() {
    return (
        <Card>
            <div className="flex justify-between">
                <div>
                    <p className="title fw-500 mt-0 mb-2">
                      Balance
                    </p>
                    <div className='balance flex align-center'>
                        <div className='currency-symbol fw-500 color-gray'>$</div>
                        <div className='balance-value fw-500'>0,00</div>
                    </div>
                    <div className='helper color-gray fw-500'>
                      Your total balance
                    </div>
                </div>
                <div className='flex flex-column pl-2'>
                    <Button
                      to="/buy"
                      as={Link}
                      title="Deposit"
                      color="orange"
                      className="mb-2"
                    />
                    <Button
                      to="/send"
                      as={Link}
                      title="Send"
                      color="white"
                    />
                </div>
            </div>
        </Card>
    );
};
