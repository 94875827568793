import React, { useState, useRef } from 'react';

import { IWallet } from 'type';
import eth from 'assets/images/eth.png';
import btc from 'assets/images/btc.png';
import usdt from 'assets/images/usdt.png';
import trx from 'assets/images/trx.png';
import useAnimatedValue from 'hooks/useAnimatedValue';
import { ReactComponent as Copy } from 'assets/images/icons/copy.svg';
import { ReactComponent as Check } from 'assets/images/icons/check.svg';
import { ReactComponent as Dots } from 'assets/images/icons/dots.svg';
import { ReactComponent as Refresh } from 'assets/images/icons/refresh.svg';
import { ReactComponent as QuestionMark } from 'assets/images/question_mark_assets.svg';
import useClickOutside from 'hooks/useClickOutside';
import AssetDropdown from './AssetDropdown';

const imgs: any = { eth, btc, trx, usdt };

type AssetProps = {
  asset: IWallet;
};

const Asset: React.FC<AssetProps> = ({ asset }) => {
  const [isCopiedAddress, setIsCopiedAddress] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { value: usdBalance } = useAnimatedValue(0, asset.usdBalance, 2);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => setIsOpen(false));

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(asset.address).then(() => {
      setIsCopiedAddress(true);
      setTimeout(() => setIsCopiedAddress(false), 1500);
    });
  };

  return (
    <div className="Asset flex bg-gray-100">
      <div className="Asset__wrapper w-100">
        <div className="Asset__addressWrapper flex">
          {imgs[asset.ticker.toLocaleLowerCase()] || asset.image ? (
            <img src={imgs[asset.ticker.toLocaleLowerCase()] || asset.image} alt="icon" className="mr-1 mr-md-2" />
          ) : (
            <QuestionMark width={40} height={40} className="mr-1 mr-md-2" />
          )}
          <div className="flex flex-column w-75">
            <p className="Asset__title mt-0">{asset.name}</p>
            <div className="flex">
              <p className="Asset__address font-sm m-0 color-gray text-truncate">
                {asset.address?.slice(0, -5)}
              </p>
              <p className="Asset__address-end font-sm mt-0 mb-0 color-gray">
                {asset.address?.slice(-5)}
              </p>
              <button disabled={isCopiedAddress} className="btn-reset ml-1" onClick={() => handleCopyAddress()}>
                {isCopiedAddress ? <Check stroke="#5BD548" width={14} height={15} /> : <Copy />}
              </button>
            </div>
          </div>
        </div>
        <div className="Asset__values ml-4">
          <p className="fw-500 font-sm text-nowrap">
            {`${asset.ticker.toUpperCase()} ${
              asset.ticker.toUpperCase() === 'USDT' ? asset.network.toUpperCase() : ''
            }`}
          </p>
          <p className="fw-500 font-sm text-nowrap">{asset.balance ? asset.balance.toFixed(5) : 0}</p>
          <p className="font-sm text-nowrap color-gray">{`≈ $${usdBalance}`}</p>
        </div>
        <div className="Asset__actions">
          <button className="btn-reset none block-lg">
            <Refresh />
          </button>
          <div className="flex flex-column none-lg align-end">
            <button className="btn-reset fw-500 font-sm text-nowrap block none-lg">
              {asset.balance ? asset.balance.toFixed(5) : 0} <Refresh width={12} height={12} />
            </button>
            <p className="font-sm text-nowrap color-gray mt-1 mb-0">{`≈ $${asset.usdBalance.toFixed(2)}`}</p>
          </div>
          <div className="relative flex align-center" ref={dropdownRef}>
            <button className="btn-reset" onClick={() => setIsOpen(true)}>
              <Dots />
            </button>
            {isOpen ? <AssetDropdown ticker={asset.ticker} type={asset.type || 'coin'} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Asset;
