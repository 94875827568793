import React from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { hideCustomToken } from 'store/features/walletsSlice';

type AssetDropdownProps = {
  ticker: string;
  type: string;
};

const AssetDropdown: React.FC<AssetDropdownProps> = ({ ticker, type = 'coin' }) => {
  const dispatch = useAppDispatch();
  const handleHideClick = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(hideCustomToken({ ticker }));
  };
  return (
    <div className="Asset__dropdown absolute">
      <ul className="Asset__dropdown_menu">
        <li>
          <Link to={`/send?currency=${ticker}`} className="fw-400 color-black">
            Send
          </Link>
        </li>
        <li>
          <Link to={`/buy?currency=${ticker}`} className="fw-400 color-black">
            Top up
          </Link>
        </li>
        {type === 'custom-token' && (
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a onClick={handleHideClick} className="fw-400 color-black">
              Hide
            </a>
          </li>
        )}
        {/* <li>
          <Link to="/" className="fw-400 color-black">
            Exchange
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default AssetDropdown;
