import React, { useEffect, useLayoutEffect, useCallback } from 'react';
import { AxiosError } from 'axios';
import { useLocation } from 'react-router-dom';

import Assets from './components/Assets';
import { Balance, Suggestion, CardCurrency } from 'components';
import { getCoins } from 'store/features/coinsSlice';
import { useAppSelector, useAppDispatch } from 'hooks';
import './Dashboard.scss';
import { getBalanceHistory } from 'store/features/balanceHistorySlice';
import { getWallets, selectWallets } from 'store/features/walletsSlice';
import { addNotification } from 'store/features/notificationsSlice';
import Spinner from 'components/Spinner';

interface ErrorResponse {
  error: string;
  isOpen: boolean;
}

export const Dashboard = () => {
  const { data, loading } = useAppSelector(selectWallets);
  const dispatch = useAppDispatch();
  const coins = useAppSelector((state) => state.coins.list);
  const location = useLocation();

  const handleError = useCallback(
    (err: AxiosError) => {
      if (err.isAxiosError && err.response) {
        const data = err.response.data as ErrorResponse;
        data.isOpen = true;
        dispatch(addNotification({ title: 'Error!', message: data.error, type: 'error' }));
      }
    },
    [dispatch]
  );

  useLayoutEffect(() => {
    if (!location.state?.isNavFromLogin) {
      dispatch(getWallets());
      dispatch(getBalanceHistory());
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (!coins.length) dispatch(getCoins());
  }, [dispatch, coins?.length, handleError]);

  return (
    <div className="dashboard">
      <div className="dashboard-content">
        <div className="dashboard-content-main">
          {
            !loading || (loading && data.totalBalance) ?
            <Balance balance={data.totalBalance} />
            :
            <div className="relative p-8">
              <Spinner
                isCentered
                size="sm"
              />
            </div>
          }
          <Assets assets={data.balances} />
          {data.totalBalance ? (
            <div className="balance-empty-bar __dashboard_bottom">
              <div className="balance-empty-bar-currencies">
                <CardCurrency name="btc" />
                <CardCurrency name="eth" />
              </div>
              <div className="balance-empty-bar-currencies">
                <CardCurrency name="sol" />
                <CardCurrency name="doge" />
              </div>
            </div>
          ) : null}
        </div>
        <div className="dashboard-content-aside">
          <div className="suggestion-container">
            <Suggestion />
          </div>
        </div>
      </div>
    </div>
  );
};
