import axios, { AxiosResponse, AxiosError } from 'axios';

export const sendRequest = async (
  method: string,
  url: string,
  data?: object,
  handleSuccess?: (res: AxiosResponse) => void,
  handleError?: (err: AxiosError) => void,
  handleFinally?: () => void
) => {
  const token = localStorage.getItem('Access-Token');

  const headers: { [key: string]: string } = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  await axios({
    method: method,
    url: `${process.env.REACT_APP_BACKEND_URL}${url}`,
    data: data,
    headers: headers,
  })
    .then((res: AxiosResponse) => {
      if (handleSuccess) {
        handleSuccess(res);
      }
    })
    .catch((err: AxiosError) => {
      if (err?.response?.status === 401) {
        localStorage.removeItem('Access-Token');
        window.location.href = '/login';
      }
      if (handleError) {
        handleError(err);
      }
    })
    .finally(() => {
      if (handleFinally) {
        handleFinally();
      }
    });
};
