import React from 'react';

import './Button.scss';

interface IButton<T extends React.ElementType> extends React.HtmlHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  as?: T;
  title: string;
  color: string;
  clickHandler?: () => void;
  sizes?: { width: string; height: string };
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  isRounded?: boolean;
  to?: string;
};

export const Button: React.FC<IButton<any>> = ({
  as: Component = 'button',
  title,
  color,
  clickHandler,
  sizes,
  className="",
  type,
  isRounded,
  disabled,
  ...props
}) => {
  return (
    <Component
      className={`button ${color}${isRounded ? ' button-rounded' : ''} ${className}`}
      onClick={clickHandler}
      style={sizes && { width: `${sizes.width}`, height: `${sizes.width}` }}
      type={type}
      disabled={disabled}
      {...props}
    >
      {title}
    </Component>
  );
};
